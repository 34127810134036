import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Upload,
  message,
} from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import clsx from 'clsx';
import { capitalize, filter, includes, map, omit, toUpper } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import {
  ALLOWED_IMAGE_TYPES,
  BREAKPOINTS,
  FILE_SIZE_20MB,
  REPORT_CONFIG_DETAILS,
} from '../../../common/constants';
import {
  compressImage,
  fileUpload,
  formValidatorRules,
  getBase64,
} from '../../../common/utils';
import CommonCard from '../../../components/CommonCard';
import CommonSelect from '../../../components/CommonSelect';
import { GET_URLS } from '../../approver/graphql/Queries';
import { UPDATE_PROJECT_REPORT_CONFIG } from '../graphql/Mutation';
import { GET_PROJECT_REPORT_CONFIG } from '../graphql/Queries';

const { Option } = CommonSelect;
const { characterWithoutWhiteSpace, required } = formValidatorRules;

const ReportConfigModal = (props) => {
  const { showModal, setShowModal, projectId, projectData } = props;
  const [form] = Form.useForm();
  const isReportTitleRequired = useWatch('reportTitleRequired', form);
  const [fileList, setFileList] = useState([]);
  const [imageKeys, setImageKeys] = useState([]);
  const [showImageSizeError, setShowImageSizeError] = useState(false);
  let files;
  const [previewImage, setPreviewImage] = useState([]);
  const isMobileViewport = useMedia(`(max-width: ${BREAKPOINTS.mobile}px)`);
  const [isLoading, setIsLoading] = useState(false);

  const [getProjectReportConfig, { data, loading }] = useLazyQuery(
    GET_PROJECT_REPORT_CONFIG,
    {
      variables: {
        projectId,
      },
      fetchPolicy: 'network-only',
    },
  );

  const [updateProjectReportConfig] = useMutation(
    UPDATE_PROJECT_REPORT_CONFIG,
    {
      onCompleted() {
        getProjectReportConfig();
        setShowModal(false);
        setIsLoading(false);
      },
      onError() {
        setIsLoading(false);
      },
    },
  );

  const submitData = async (urls) => {
    const formValues = form.getFieldsValue(true);
    updateProjectReportConfig({
      variables: {
        id: data?.getProjectReportConfig?.id,
        data: { ...formValues, projectPhotos: urls },
      },
    });
  };

  const defaultList = (imageArr) => {
    return map(imageArr, (image, index) => ({
      uid: index,
      url: image,
      key: image?.split('/')?.[3],
    }));
  };

  const [fetchSignedUrl] = useLazyQuery(GET_URLS, {
    fetchPolicy: 'network-only',
    onError() { },
    onCompleted: async (response) => {
      const filteredFileList = filter(fileList, 'name');
      const promises = map(
        response?.getSignedPutUrls?.signedUrls,
        async (imageUrl, index) => {
          const callBack = async (image) => {
            await fileUpload(imageUrl, image).catch(() => {
              return message.error('Image upload failed!');
            });
          };
          await compressImage({
            file:
              filteredFileList?.[index]?.originFileObj ||
              filteredFileList?.[index],
            fileType: filteredFileList?.[index]?.type?.split('/')?.[1],
            callBack,
          });
        },
      );
      await Promise.all(promises);
      setImageKeys((prev) => [...prev, ...response?.getSignedPutUrls?.keys]);
      submitData([...imageKeys, ...response?.getSignedPutUrls?.keys]);
    },
  });

  useEffect(() => {
    getProjectReportConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setFileList(defaultList(data?.getProjectReportConfig?.projectPhotos));
      setImageKeys((prev) => [
        ...prev,
        ...map(
          data?.getProjectReportConfig?.projectPhotos,
          (ee) => ee?.split('/')?.[3],
        ),
      ]);
      form.setFieldsValue({
        ...omit(data?.getProjectReportConfig, [
          'createdAt',
          'id',
          'updatedAt',
          'projectPhotos',
          '__typename',
        ]),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleCancel = () => {
    setShowModal(false);
  };

  const getFormItem = (type, item) => {
    switch (type) {
      case 'dropdown':
        return (
          <CommonSelect placeholder="Sequence">
            {map(item?.options, (value) => (
              <Option key={value} value={toUpper(value)}>
                {capitalize(value)}
              </Option>
            ))}
          </CommonSelect>
        );
      case 'radio':
        return (
          <Radio.Group className="d-flex agency-type-radio justify-end">
            {map(item?.options, (value) => (
              <Radio value={value === 'Yes'}> {capitalize(value)}</Radio>
            ))}
          </Radio.Group>
        );
      default:
        break;
    }
  };

  const submitForm = () => {
    setIsLoading(true);
    const fileSend = (anotherFileList) => {
      return map(anotherFileList, (file) => ({
        fileName: `tenant/project-logos/${file?.name}`.replace(/ /g, '_'),
        contentType: file?.type,
        acl: 'public-read',
        isTenantModule: true,
      }));
    };
    const filteredFileList = filter(fileList, 'name');
    if (filteredFileList?.length) {
      fetchSignedUrl({
        variables: {
          data: fileSend(filteredFileList),
        },
      });
    } else {
      submitData(imageKeys);
    }
  };
  const handleOnChange = (info) => {
    const newFileList = filter(info?.fileList, (file) => {
      setShowImageSizeError(false);
      if (file?.size > FILE_SIZE_20MB) {
        setShowImageSizeError(true);
        return false;
      }
      if (!file?.url && !includes(ALLOWED_IMAGE_TYPES, file?.type)) {
        message.destroy();
        message.error('File type should be PNG, JPG, JPEG');
        return false;
      }
      return file;
    });
    setFileList(newFileList);
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      const preview = await getBase64(file?.originFileObj);
      files = file.url || preview;
    }
    setPreviewImage((oldData) => [...oldData, files]);
  };

  const handleRemove = (fileIndex) => {
    setImageKeys((prev) => filter(prev, (record) => record !== fileIndex?.key));
    form.validateFields();
  };

  return (
    <div id="report-config-modal">
      <Modal
        maskClosable={false}
        centered
        visible={showModal}
        onCancel={handleCancel}
        footer={null}
        width={678}
        closable={false}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('report-config-modal')
        }
      >
        <div className="notification">
          <h2>EQC Report Configurator</h2>
          <div key={1}>
            <Form
              form={form}
              className="report-config-form"
              layout="vertical"
              onFinish={submitForm}
              disabled={!projectData?.isActive}
            >
              <CommonCard className="notification-card" loading={loading}>
                {map(REPORT_CONFIG_DETAILS, (item, index) => {
                  return (
                    <>
                      <Row
                        justify="space-between"
                        align="middle"
                        className="notification-row"
                        key={item?.key}
                        gutter={10}
                      >
                        <Col
                          span={
                            // eslint-disable-next-line no-nested-ternary
                            isMobileViewport
                              ? 24
                              : item?.type === 'upload'
                                ? 24
                                : 16
                          }
                        >
                          <div className={clsx('title')}>{item?.title}</div>
                          <div className="description">{item?.description}</div>
                          {item?.type === 'upload' && (
                            <>
                              <Upload
                                maxCount={4}
                                accept=".png,.jpeg,.jpg"
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onChange={(info) => {
                                  handleOnChange(info);
                                }}
                                beforeUpload={() => {
                                  return false;
                                }}
                                onPreview={(e) => {
                                  handlePreview(e);
                                }}
                                onRemove={(e) => handleRemove(e)}
                                showUploadList={{ showPreviewIcon: false }}
                              >
                                <div
                                  className="height-percent-100 width-percent-100 d-flex align-center justify-center pointer"
                                  onClick={(e) => {
                                    if (fileList?.length === 4) {
                                      e.stopPropagation();
                                    }
                                  }}
                                >
                                  <Button
                                    disabled={
                                      fileList?.length === 4 ||
                                      !projectData?.isActive
                                    }
                                    type="primary"
                                    shape="round"
                                  >
                                    Add
                                  </Button>
                                </div>
                              </Upload>
                              <div>
                                {React.Children.map(previewImage, (photo) => (
                                  <Image src={photo} />
                                ))}
                              </div>
                              {showImageSizeError && (
                                <div className="mt-10 mb-10 text-danger">
                                  * Maximum file size is 20MB
                                </div>
                              )}
                            </>
                          )}
                        </Col>
                        <Col
                          span={isMobileViewport ? 24 : 8}
                          className={
                            isMobileViewport
                              ? 'd-flex align-start mt-10 flex-vertical'
                              : ''
                          }
                        >
                          {item?.type !== 'upload' && (
                            <Form.Item name={item?.formName}>
                              {getFormItem(item?.type, item)}
                            </Form.Item>
                          )}
                          {item?.formName === 'reportTitleRequired' &&
                            isReportTitleRequired && (
                              <Form.Item
                                rules={[
                                  required,
                                  characterWithoutWhiteSpace,
                                  {
                                    max: 50,
                                    message: 'Maximum 50 characters allowed',
                                  },
                                ]}
                                name="reportTitle"
                              >
                                <Input allowClear placeholder="Report Title" />
                              </Form.Item>
                            )}
                        </Col>
                      </Row>
                      {isMobileViewport &&
                        index < REPORT_CONFIG_DETAILS?.length - 1 && (
                          <Divider />
                        )}
                    </>
                  );
                })}
              </CommonCard>
              <div className="form-buttons mt-10">
                <Button
                  shape="round"
                  className="cancel-button"
                  onClick={handleCancel}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  shape="round"
                  type="primary"
                  className="save-button"
                  htmlType="submit"
                  disabled={!data?.getProjectReportConfig?.id || isLoading}
                  loading={isLoading}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReportConfigModal;
