import { InboxOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Descriptions, Form, Input, Row, Tabs, Tag } from 'antd';
import clsx from 'clsx';
import {
  capitalize,
  debounce,
  dropRight,
  filter,
  includes,
  keys,
  map,
  nth,
  omit,
} from 'lodash';
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import {
  ArchiveOutline,
  CommentWhiteIcon,
  DeleteButton,
  EditIcon,
  SaveOutline,
} from '../../../../../../../assets/svg';
import {
  ADDONS,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  CHECKLIST_ITEM_TAB_KEYS,
  CHECKLIST_STATUS_CLASSNAME,
  CHECKLIST_STATUS_LABEL,
  CONFIRMATION_TYPES,
  EQC_STAGE_CONFIG_LABEL,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
  ROUTES,
  TAB_KEYS,
  UOMS,
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../../common/utils';
import CanPerform from '../../../../../../../components/CanPerform';
import CommonCard from '../../../../../../../components/CommonCard';
import CommonSelect from '../../../../../../../components/CommonSelect';
import EllipsisText from '../../../../../../../components/EllipsisText';
import RouterPrompt from '../../../../../../../components/RouterPrompt';
import {
  DELETE_DRAFT_PROJECT_EQC_TYPE,
  DRAFT_PROJECT_EQC_TYPE,
  PUBLISH_DRAFT_PROJECT_EQC_TYPE,
  PUBLISH_EQC_TYPE,
  UPDATE_PROJECT_EQC_TYPE,
} from '../../../../../graphql/Mutation';
import AuditorCommentModal from '../../eqc/eqcDetails/AuditorCommentModal';
import ChangeLog from './ChangeLog';
import ConfirmationModal from './ConfirmationModal';
import PublishEqcTypeModal from './PublishEqcTypeModal';
import StageItemList from './stageItemList/StageItemList';
import AddApprovalModal from './stageList/AddApprovalModal';
import StageList from './stageList/StageList';
import StageSettings from './stageList/StageSettings';

const { required, characterWithoutWhiteSpace } = formValidatorRules;
const EqcDetailContent = ({
  eqcTypeData,
  onStageDataChange,
  refetch,
  pageFilters,
}) => {
  const [form] = Form.useForm();
  const [stageId, setStageId] = useState();
  const [title, setTitle] = useState();
  const [stageData, setStageData] = useState();
  const [selectedStageData, setSelectedStageData] = useState();
  const [isSequenceChanged, setIsSequenceChanged] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [showAuditorCommentsModal, setShowAuditorCommentsModal] =
    useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [activeTab, setActiveTab] = useState(
    CHECKLIST_ITEM_TAB_KEYS.CHECKLISTS,
  );
  const [confirmationType, setConfirmationType] = useState(
    CONFIRMATION_TYPES?.EDIT,
  );
  const [alreadyDraftedUserData, setAlreadyDraftedUserData] = useState();
  const { getCurrentUser, dispatch } = useContext(AppContext);
  const [tabKey, setTabKey] = useState(Date.now());
  const currentUser = getCurrentUser();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const {
    location: { pathname },
    navigate,
  } = useRouter();
  const refetchRef = useRef(null);

  const [updateProjectEqcType] = useMutation(UPDATE_PROJECT_EQC_TYPE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.EDIT_PROJECT_CHECKLIST, {
        label: GA_LABEL.EDIT_PROJECT_CHECKLIST,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: eqcTypeData?.project?.id,
        project_checklist_id: eqcTypeData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      refetch();
    },
  });

  useImperativeHandle(
    refetchRef,
    () => {
      return {
        ...refetchRef.current,
        getProjectEqcType() {
          refetch();
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleGoBack = () => {
    setIsEditable(false);
    setShowModal(false);
    if (
      includes(
        [PROJECT_CHECKLIST_STATUS.PUBLISHED, PROJECT_CHECKLIST_STATUS.ARCHIVED],
        eqcTypeData?.projectEqcType?.status,
      )
    ) {
      navigate(dropRight(pathname?.split('/'))?.join('/'), {
        state: { pageFilters },
      });
    }
  };

  const [publishDraftProjectEqcType] = useMutation(
    PUBLISH_DRAFT_PROJECT_EQC_TYPE,
    {
      onError() {
        setIsActionLoading(false);
      },
      onCompleted() {
        Event(GA_EVENT.PUBLISH_PROJECT_CHECKLIST_VERSION, {
          label: GA_LABEL.PUBLISH_PROJECT_CHECKLIST_VERSION,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: eqcTypeData?.project?.id,
          project_checklist_id: eqcTypeData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setIsActionLoading(false);
        handleGoBack();
      },
    },
  );
  const [draftProjectEqcType] = useMutation(DRAFT_PROJECT_EQC_TYPE, {
    onError() {
      setIsActionLoading(false);
    },
    onCompleted(res) {
      Event(GA_EVENT.DRAFT_NEW_PROJECT_CHECKLIST_VERSION, {
        label: GA_LABEL.DRAFT_NEW_PROJECT_CHECKLIST_VERSION,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: eqcTypeData?.project?.id,
        project_checklist_id: eqcTypeData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setIsActionLoading(false);
      if (!res?.draftProjectEqcType?.draftCreatorInfo) {
        navigate(
          `${ROUTES.PROJECTS}/${eqcTypeData?.project?.id}/${TAB_KEYS.CHECKLISTS}/${res?.draftProjectEqcType?.draftProjectEqcTypeId}/draft`,
          { state: pageFilters },
        );
      } else {
        setShowModal(false);
        setAlreadyDraftedUserData(res?.draftProjectEqcType);
        setConfirmationType(CONFIRMATION_TYPES?.MULTIPLE_USER);
        setShowModal(true);
      }
    },
  });

  const [deleteDraftProjectEqcType] = useMutation(
    DELETE_DRAFT_PROJECT_EQC_TYPE,
    {
      onError() {
        setIsActionLoading(false);
      },
      onCompleted() {
        setIsEditable(false);
        Event(GA_EVENT.DISCARD_PROJECT_CHECKLIST_CHANGES, {
          label: GA_LABEL.DISCARD_PROJECT_CHECKLIST_CHANGES,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: eqcTypeData?.project?.id,
          project_checklist_id: eqcTypeData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setIsActionLoading(false);
        setShowModal(false);
        if (confirmationType === CONFIRMATION_TYPES.DISCARD) {
          navigate(-1);
        }
      },
    },
  );

  const [publishProjectEqcType] = useMutation(PUBLISH_EQC_TYPE);

  useEffect(() => {
    if (eqcTypeData?.status !== PROJECT_CHECKLIST_STATUS?.UNPUBLISHED) {
      dispatch({
        type: 'SET_SHOW_PROMPT',
        data: isEditable,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable]);

  useEffect(() => {
    form.setFieldsValue({
      iso: eqcTypeData?.iso,
      uom: eqcTypeData?.uom,
      name: eqcTypeData?.name,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcTypeData]);

  useEffect(() => {
    if (
      includes(
        [
          PROJECT_CHECKLIST_STATUS?.UNPUBLISHED,
          PROJECT_CHECKLIST_STATUS?.DRAFT,
        ],
        eqcTypeData?.status,
      ) &&
      !includes(pathname?.split('/'), 'changelog')
    ) {
      setIsEditable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcTypeData]);

  useMemo(() => {
    let record;
    if (stageId && stageData?.length > 0) {
      stageData?.forEach((item) => {
        if (item?.id === stageId) {
          record = item;
        } else if (item?.rfiStage?.id === stageId) {
          record = item?.rfiStage;
        }
      });
      setStageId(record?.id || stageId);
    } else {
      record = nth(stageData, 0);
      setStageId(record?.id);
    }
    const data = {
      ...record,
      pass: includes(record?.stageConfiguration, EQC_STAGE_CONFIG_LABEL.BYPASS),
      skip: includes(record?.stageConfiguration, EQC_STAGE_CONFIG_LABEL.SKIP),
    };
    setSelectedStageData(data);
    setTabKey(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageData, stageId]);

  useEffect(() => {
    onStageDataChange(stageData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageData]);

  const onFormSubmit = debounce(async () => {
    if (
      filter(form.getFieldsError(), (data) => data?.errors?.length > 0)
        ?.length === 0
    ) {
      try {
        await updateProjectEqcType({
          variables: {
            id: eqcTypeData?.id,
            data: {
              ...form.getFieldsValue(),
              projectId: Number(eqcTypeData?.project?.id),
            },
          },
        });
      } catch (error) {
        return error;
      }
    }
  }, 1000);

  const handleArchive = async () => {
    const { projectAgencyEqcTypes, projectUserEqcTypes } = eqcTypeData;
    const newValues = omit(eqcTypeData, [
      'project',
      '__typename',
      'id',
      'projectUserEqcTypes',
      'projectAgencyEqcTypes',
      'creator',
      'projectEqcTypeStageCount',
      'isAudited',
      'projectEqcType',
      'activityLogCount',
    ]);
    try {
      const response = await publishProjectEqcType({
        variables: {
          id: eqcTypeData?.id,
          data: {
            ...newValues,
            projectAgencyIds: map(
              projectAgencyEqcTypes,
              (agency) => agency?.id,
            ),
            projectUserIds: map(projectUserEqcTypes, (user) => user?.id),
            projectRFIUserIds: map(projectUserEqcTypes, (user) => user?.id),
            projectId: Number(eqcTypeData?.project?.id),
            status: PROJECT_CHECKLIST_STATUS?.ARCHIVED,
          },
        },
      });
      if (response?.data?.publishProjectEqcType) {
        Event(GA_EVENT.UNPUBLISH_PROJECT_CHECKLIST, {
          label: GA_LABEL.UNPUBLISH_PROJECT_CHECKLIST,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: eqcTypeData?.project?.id,
          project_checklist_id: eqcTypeData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setIsActionLoading(false);
        setShowModal(false);
        navigate(
          `${ROUTES.PROJECTS}/${eqcTypeData?.project?.id}/${TAB_KEYS.CHECKLISTS}/${response?.data?.publishProjectEqcType?.archivedProjectEqcTypeId}`,
          { state: { pageFilters } },
        );
      }
    } catch (error) {
      setIsActionLoading(false);
      return error;
    }
  };

  const handleAction = async (type) => {
    switch (type || confirmationType) {
      case CONFIRMATION_TYPES.EDIT:
      case CONFIRMATION_TYPES?.MULTIPLE_USER:
      case CONFIRMATION_TYPES?.UNARCHIVE:
        draftProjectEqcType({
          variables: {
            data: {
              projectEqcTypeId: Number(eqcTypeData?.id),
              projectId: Number(eqcTypeData?.project?.id),
              discardDraft: !!alreadyDraftedUserData?.draftProjectEqcTypeId,
            },
          },
        });
        break;
      case CONFIRMATION_TYPES.SAVE:
        publishDraftProjectEqcType({
          variables: { id: eqcTypeData?.id },
        });
        break;
      case CONFIRMATION_TYPES.ARCHIVE:
        await handleArchive();
        break;
      case CONFIRMATION_TYPES.DISCARD:
        deleteDraftProjectEqcType({
          variables: {
            id: eqcTypeData?.id,
          },
        });
        break;
      default:
        break;
    }
  };
  const { state } = useContext(AppContext);

  const items = [
    {
      key: CHECKLIST_ITEM_TAB_KEYS.CHECKLISTS,
      label:
        selectedStageData?.type === ADDONS.RFI ? 'RFI points' : 'Checkpoints',
      children: (
        <div className="details">
          <StageItemList
            stageId={stageId}
            eqcTypeData={eqcTypeData}
            stageData={stageData}
            selectedStageData={selectedStageData}
            setIsSequenceChanged={setIsSequenceChanged}
            isEditable={
              selectedStageData?.type === ADDONS.RFI ? false : isEditable
            }
          />
        </div>
      ),
    },
    ...(CanPerform({
      action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS,
      type: ALLOWED_ACTION_TYPE.BOTH,
    })
      ? [
          {
            key: CHECKLIST_ITEM_TAB_KEYS.APPROVERS,
            label: 'Approvers',
            children: (
              <div className="approvers-setting-tab">
                <AddApprovalModal
                  stageData={selectedStageData}
                  isUpdate={!!stageData}
                  setStageData={setSelectedStageData}
                  refetchStageDataWithInitialValues={refetch}
                  eqcTypeId={eqcTypeData?.id}
                  eqcTypeData={eqcTypeData}
                  isEditable={isEditable}
                  refetchRef={refetchRef}
                  isDisabled={!eqcTypeData?.project?.isActive}
                />
              </div>
            ),
          },
        ]
      : []),
    {
      key: CHECKLIST_ITEM_TAB_KEYS.SETTING,
      label: 'Settings',
      children: (
        <div className="checklist-setting-tab">
          <StageSettings
            stageData={selectedStageData}
            isUpdate={!!selectedStageData}
            setStageData={setSelectedStageData}
            refetchRef={refetchRef}
            eqcTypeId={eqcTypeData?.id}
            isEditable={isEditable}
            eqcTypeData={eqcTypeData}
            isDisabled={!eqcTypeData?.project?.isActive}
          />
        </div>
      ),
    },
  ];
  return (
    <div className="mb-20">
      <RouterPrompt
        openPrompt={state?.showPrompt}
        pageFilters={pageFilters}
        handleContinue={() => {
          if (
            includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              eqcTypeData?.projectEqcType?.status,
            )
          ) {
            handleAction(CONFIRMATION_TYPES?.DISCARD);
          } else {
            handleGoBack();
          }
        }}
      />
      <CommonCard className="checklist-details mb-24">
        <Row justify="space-between" align="middle">
          <Col span={isDesktopViewport ? 18 : 24}>
            {(!isEditable ||
              includes(
                [
                  PROJECT_CHECKLIST_STATUS.PUBLISHED,
                  PROJECT_CHECKLIST_STATUS.ARCHIVED,
                ],
                eqcTypeData?.projectEqcType?.status,
              ) ||
              !CanPerform({
                action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS,
                type: ALLOWED_ACTION_TYPE.BOTH,
              })) && (
              <EllipsisText
                className="h2-typography mb-10"
                text={eqcTypeData?.name}
              />
            )}
            {!includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              eqcTypeData?.projectEqcType?.status,
            ) &&
              isEditable &&
              CanPerform({
                action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS,
                type: ALLOWED_ACTION_TYPE.BOTH,
              }) && (
                <div className="checklist-basic-details">
                  <Form
                    layout="vertical"
                    className="checklist-details-form d-flex"
                    form={form}
                    onFieldsChange={() => onFormSubmit()}
                  >
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[
                        required,
                        characterWithoutWhiteSpace,
                        {
                          max: 250,
                          message: 'Content cannot be more than 250 characters',
                        },
                      ]}
                    >
                      <Input placeholder="Enter Name" allowClear />
                    </Form.Item>
                  </Form>
                </div>
              )}
            {!includes(pathname?.split('/'), 'changelog') &&
              includes(
                [
                  PROJECT_CHECKLIST_STATUS.PUBLISHED,
                  PROJECT_CHECKLIST_STATUS.ARCHIVED,
                ],
                eqcTypeData?.status,
              ) &&
              eqcTypeData?.activityLogCount > 0 && (
                <div
                  className="change-log-button"
                  onClick={() => {
                    setIsEditable(false);
                    if (!includes(pathname?.split('/'), 'changelog')) {
                      navigate(
                        `${ROUTES.PROJECTS}/${eqcTypeData?.project?.id}/${TAB_KEYS.CHECKLISTS}/${eqcTypeData?.id}/changelog`,
                        { state: { pageFilters } },
                      );
                    }
                  }}
                >
                  ChangeLog
                </div>
              )}
            <div
              className={clsx(
                'd-flex checklist-basic-details ',
                isDesktopViewport && 'mt-24',
              )}
            >
              {isEditable &&
                CanPerform({
                  action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS,
                  type: ALLOWED_ACTION_TYPE.BOTH,
                }) && (
                  <Form
                    layout="vertical"
                    className="checklist-details-form d-flex"
                    form={form}
                    onFieldsChange={() => onFormSubmit()}
                  >
                    <Form.Item
                      name="iso"
                      label="Reference Number"
                      rules={[
                        characterWithoutWhiteSpace,
                        {
                          max: 250,
                          message: 'Content cannot be more than 250 characters',
                        },
                      ]}
                    >
                      <Input placeholder="Enter Reference Number" allowClear />
                    </Form.Item>
                    <Form.Item name="uom" label="UOM" rules={[required]}>
                      <CommonSelect
                        options={[
                          ...map(keys(UOMS), (item) => {
                            return {
                              key: item,
                              value: item,
                              label: UOMS[item],
                            };
                          }),
                        ]}
                      />
                    </Form.Item>
                  </Form>
                )}
              <Descriptions
                column={isDesktopViewport ? 6 : 3}
                layout="vertical"
                colon={false}
                className="d-flex"
              >
                {(!isEditable ||
                  !CanPerform({
                    action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS,
                    type: ALLOWED_ACTION_TYPE.BOTH,
                  })) && (
                  <>
                    <Descriptions.Item
                      label="Reference Number"
                      className={clsx(!isDesktopViewport && 'common-item')}
                      span={2}
                    >
                      <EllipsisText text={eqcTypeData?.iso || '-'} />
                    </Descriptions.Item>
                    <Descriptions.Item label="UOM">
                      <EllipsisText text={UOMS[eqcTypeData?.uom] || '-'} />
                    </Descriptions.Item>
                  </>
                )}
                <Descriptions.Item
                  label="Status"
                  className={clsx(!isDesktopViewport && 'common-item')}
                >
                  <Tag
                    className={CHECKLIST_STATUS_CLASSNAME[eqcTypeData?.status]}
                  >
                    {capitalize(CHECKLIST_STATUS_LABEL[eqcTypeData?.status])}
                  </Tag>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Col>
          {!includes(pathname?.split('/'), 'changelog') && (
            <Col
              className="flex-vertical d-flex justify-end"
              span={isDesktopViewport ? 6 : 24}
            >
              {eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ? (
                <CanPerform
                  action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
                  type={ALLOWED_ACTION_TYPE.BOTH}
                >
                  <div className="d-flex justify-end">
                    <Button
                      shape="round"
                      type="primary"
                      icon={<InboxOutlined />}
                      className={clsx(
                        !isDesktopViewport && 'justify-center fill-width',
                        'unarchive-button-checklist d-flex',
                      )}
                      onClick={() => {
                        setConfirmationType(CONFIRMATION_TYPES?.UNARCHIVE);
                        setShowModal(true);
                      }}
                    >
                      Unarchive
                    </Button>
                  </div>
                </CanPerform>
              ) : (
                <div className="d-flex justify-end">
                  {isEditable ? (
                    <CanPerform
                      action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
                      type={ALLOWED_ACTION_TYPE.BOTH}
                    >
                      {eqcTypeData?.status !==
                        PROJECT_CHECKLIST_STATUS.UNPUBLISHED && (
                        <Button
                          shape="round"
                          type=""
                          icon={<DeleteButton width={32} height={32} />}
                          className={clsx(
                            !isDesktopViewport &&
                              'width-percent-100 justify-center',
                            'delete-button-checklist delete-button d-flex',
                          )}
                          onClick={() => {
                            setConfirmationType(CONFIRMATION_TYPES?.DISCARD);
                            setShowModal(true);
                          }}
                        >
                          Discard
                        </Button>
                      )}
                      <Button
                        shape="round"
                        type="primary"
                        icon={<SaveOutline />}
                        className={clsx(
                          !isDesktopViewport &&
                            'width-percent-100 justify-center',
                          'edit-button-checklist d-flex',
                        )}
                        onClick={() => {
                          if (
                            includes(
                              [
                                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                                PROJECT_CHECKLIST_STATUS.ARCHIVED,
                              ],
                              eqcTypeData?.projectEqcType?.status,
                            )
                          ) {
                            setConfirmationType(CONFIRMATION_TYPES?.SAVE);
                            setShowModal(true);
                          } else {
                            setShowPublishModal(true);
                          }
                        }}
                      >
                        {eqcTypeData?.status ===
                        PROJECT_CHECKLIST_STATUS.UNPUBLISHED
                          ? 'Go Live'
                          : 'Save'}
                      </Button>
                    </CanPerform>
                  ) : (
                    <CanPerform
                      action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
                      type={ALLOWED_ACTION_TYPE.BOTH}
                    >
                      <div className="form-buttons width-percent-100">
                        <Button
                          shape="round"
                          icon={<ArchiveOutline className="mr-5" />}
                          className={clsx(
                            'delete-button-checklist d-flex',
                            !isDesktopViewport &&
                              'width-percent-100 justify-center',
                          )}
                          onClick={() => {
                            setConfirmationType(CONFIRMATION_TYPES?.ARCHIVE);
                            setShowModal(true);
                          }}
                        >
                          Archive
                        </Button>
                        <Button
                          shape="round"
                          type="primary"
                          icon={<EditIcon />}
                          className={clsx(
                            'edit-button-checklist d-flex save-button',
                            !isDesktopViewport &&
                              'width-percent-100 justify-center',
                          )}
                          onClick={() => {
                            setConfirmationType(CONFIRMATION_TYPES?.EDIT);
                            setShowModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </CanPerform>
                  )}
                </div>
              )}
              <div className="d-flex justify-end">
                <Button
                  shape="round"
                  icon={<CommentWhiteIcon width="16px" heigth="16px" />}
                  className={clsx(
                    'justify-center comment-button b-0 mt-24',
                    !isDesktopViewport && 'fill-width',
                  )}
                  onClick={() => {
                    Event(GA_EVENT.VIEW_AUDITOR_COMMENT_MODAL, {
                      label: GA_LABEL.VIEW_AUDITOR_COMMENT_MODAL,
                      // eslint-disable-next-line no-undef
                      pathname: window?.location?.href,
                      project_id: eqcTypeData?.project?.id,
                      project_checklist_id: eqcTypeData?.id,
                      user_id: currentUser?.id,
                      user_name: currentUser?.name,
                      tenant_id: currentUser?.tenantUser?.tenant?.id,
                      tenant_name:
                        currentUser?.tenantUser?.tenant?.organizationName,
                    });
                    setShowAuditorCommentsModal(true);
                  }}
                  disabled={!eqcTypeData?.isAudited}
                >
                  Auditor Comments
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </CommonCard>
      {includes(pathname?.split('/'), 'changelog') ? (
        <ChangeLog eqcTypeData={eqcTypeData} />
      ) : (
        <Row gutter={[16, 16]}>
          <Col span={isDesktopViewport ? 8 : 24}>
            <StageList
              setStageId={setStageId}
              setTitle={setTitle}
              title={title}
              stageId={stageId}
              eqcTypeData={eqcTypeData}
              stageData={stageData}
              setStageData={setStageData}
              isSequenceChanged={isSequenceChanged}
              setIsSequenceChanged={setIsSequenceChanged}
              isEditable={isEditable}
              refetchRef={refetchRef}
            />
          </Col>
          <Col span={isDesktopViewport ? 16 : 24} className="project-details">
            {stageId ? (
              <Tabs
                onTabClick={setActiveTab}
                activeKey={activeTab}
                destroyInactiveTabPane
                className="checklist-item-tabs"
                key={tabKey}
                items={items}
              />
            ) : (
              <CommonCard className="stage-item-card">
                <div className="header d-flex align-center justify-center">
                  <h2 className="mb-0">
                    {stageData?.length > 0
                      ? `Please Select Stage First`
                      : `Please Add Checklist Stage To Add Checkpoints And Approvers`}
                  </h2>
                </div>
              </CommonCard>
            )}
          </Col>
        </Row>
      )}
      <ConfirmationModal
        type={confirmationType}
        showModal={showModal}
        setShowModal={setShowModal}
        handleConfirmation={handleAction}
        alreadyDraftedUserData={alreadyDraftedUserData}
        setConfirmationType={setConfirmationType}
        setIsActionLoading={setIsActionLoading}
        isActionLoading={isActionLoading}
        setAlreadyDraftedUserData={setAlreadyDraftedUserData}
      />
      <AuditorCommentModal
        setShowModal={setShowAuditorCommentsModal}
        showModal={showAuditorCommentsModal}
        sectionId={eqcTypeData?.id}
        section="EQC_TYPE"
      />
      {showPublishModal && (
        <PublishEqcTypeModal
          showModal={showPublishModal}
          setShowModal={setShowPublishModal}
          eqcTypeData={eqcTypeData}
          callback={() => handleGoBack()}
          refetchEqcTypeData={refetch}
          isRfiRequired={
            filter(stageData, (record) => record?.rfiStage)?.length > 0
          }
        />
      )}
    </div>
  );
};

export default EqcDetailContent;
