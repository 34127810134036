import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Button, Modal, Popconfirm, Tooltip } from 'antd';
import clsx from 'clsx';
import { filter, forEach, map, nth, omit, slice } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS, DEFAULT_PAGE_SIZE } from '../../common/constants';
import CommonCard from '../../components/CommonCard';
import CommonTable from '../../components/CommonTable';
import EllipsisText from '../../components/EllipsisText';
import InfiniteScrollHandler from '../../components/InfiniteScrollHandler';
import ReplaceApproverModal from './ReplaceApproverModal';
import {
  DEACTIVATE_USER_APPROVER,
  DELETE_PROJECT_USER_APPROVER,
} from './graphql/Mutations';
import { USER_EQC_TYPE_STAGE_APPROVAL_LIST } from './graphql/Queries';

const DeActiveUserModal = ({
  showModal,
  setShowModal,
  userRecord,
  projectId = '',
  refetchUserDetails,
  projectUserId,
  setUserData,
}) => {
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const initialUserFilter = {
    skip: 0,
    limit: 10,
    userId: userRecord?.id,
    projectId,
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const handleCancel = () => {
    if (setUserData) {
      setUserData();
    }
    setShowModal(false);
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userListData, setUserListData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(true);
  const [approverData, setApproverData] = useState();
  const [replacedApproverData, setReplacedApproverData] = useState([]);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [requiredStageIds, setRequiredStageIds] = useState([]);
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [replacedApprovers, setReplacedApprovers] = useState({});

  const [fetchUserData, { loading }] = useLazyQuery(
    USER_EQC_TYPE_STAGE_APPROVAL_LIST,
    {
      variables: { filter: userFilter },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.userEqcTypeStageApprovalList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.userEqcTypeStageApprovalList?.total,
        };
        setRequiredStageIds(
          res?.userEqcTypeStageApprovalList?.requiredProjectEqcTypeStageLevel,
        );
        if (scrollFlag) {
          const datalist = [...userListData, ...data];
          setUserListData(datalist);
          setScrollFlag(false);
          if (datalist?.length === res?.userEqcTypeStageApprovalList?.total) {
            setHasMore(false);
          }
        } else {
          const datalist = [...data];
          setUserListData(datalist);
        }
        setPaginationProp(pagination);
      },
      onError() { },
    },
  );

  const [
    deactivateUserApprover,
    { loading: deactivateUserLoading },
  ] = useMutation(DEACTIVATE_USER_APPROVER, {
    onError() { },
    onCompleted() {
      setShowModal(false);
      refetchUserDetails();
      if (setUserData) {
        setUserData();
      }
    },
  });

  const [
    deleteProjectUserApprover,
    { loading: deleteProjectUserLoading },
  ] = useMutation(DELETE_PROJECT_USER_APPROVER, {
    onError() { },
    onCompleted() {
      setShowModal(false);
      refetchUserDetails();
      if (setUserData) {
        setUserData();
      }
    },
  });

  useEffect(() => {
    fetchUserData({ variables: { filter: userFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddEditUser = () => {
    setShowModal(true);
  };

  const handleTableChange = (pagination, _, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
            },
          },
        },
      });
    } else {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination?.pageSize,
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination?.pageSize,
          },
        },
      });
    }
  };

  const getApproverName = (values, record, sendData = false) => {
    const fetchName = [];
    if (replacedApprovers?.[record?.id]?.length > 0) {
      forEach(replacedApprovers?.[record?.id], (approver) => {
        fetchName.push(approver?.name || approver?.user?.name);
      });
    } else {
      const { projectEqcTypeStageApprovals } = record;
      forEach(projectEqcTypeStageApprovals, (approver) => {
        const { internalApproverName, externalApproverName } = approver;
        if (internalApproverName || externalApproverName) {
          fetchName.push(internalApproverName || externalApproverName);
        }
      });
    }
    const selectedName = nth(fetchName, 0);
    if (sendData) {
      return fetchName;
    }
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }
    return '-';
  };

  const getActionButtons = (record) => {
    const isRequired =
      filter(requiredStageIds, {
        levelNumber: record?.levelNumber,
        projectEqcTypeStageId: record?.stageId,
      })?.length > 0;

    const handleReplace = (e) => {
      e?.stopPropagation?.();
      setApproverData(record);
      handleAddEditUser();
      setShowReplaceModal(true);
    };

    return isDesktopViewport ? (
      <div className="form-buttons">
        <Tooltip title="replace">
          <Button
            shape="round"
            className={clsx(
              'replace-button',
              !isRequired &&
              !replacedApprovers?.[record?.id]?.length > 0 &&
              'background-secondary',
              replacedApprovers?.[record?.id]?.length > 0 && 'replaced-button',
            )}
            onClick={handleReplace}
          >
            Replace
          </Button>
        </Tooltip>
      </div>
    ) : (
      <Button
        type="text"
        className={clsx(
          'fw-semi-bold',
          isRequired && 'text-primary',
          !isRequired &&
          !replacedApprovers?.[record?.id]?.length > 0 &&
          'text-secondary',
          replacedApprovers?.[record?.id]?.length > 0 && 'replaced-button',
        )}
        onClick={handleReplace}
      >
        Replace
      </Button>
    );
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{userFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'PROJECT',
      dataIndex: 'projectName',
      key: 'projectName',
      render: (record) => {
        return <EllipsisText text={record} />;
      },
    },
    {
      title: 'CHECKLIST',
      dataIndex: 'projectEqcTypeName',
      key: 'projectEqcTypeName',
      render: (record) => {
        return <EllipsisText text={record} />;
      },
    },
    {
      title: 'STAGE',
      dataIndex: 'name',
      key: 'isActive',
      render: (name) => {
        return <EllipsisText text={name} />;
      },
    },
    {
      title: 'LEVEL',
      dataIndex: 'levelName',
      key: 'levelName',
      render: (name) => {
        return <EllipsisText text={name} />;
      },
    },
    {
      title: 'APPROVERS',
      dataIndex: 'approvers',
      key: 'approver',
      render: (values, record) => getApproverName(values, record),
    },
    {
      align: 'right',
      render: (action, record) => getActionButtons(record),
    },
  ];

  const handleRefetch = () => {
    fetchUserData({
      variables: {
        filter: {
          ...userFilter,
          skip: userListData?.length,
          limit: DEFAULT_PAGE_SIZE,
        },
      },
    });
  };

  const handleDeactivate = async () => {
    const mutationName = projectUserId
      ? deleteProjectUserApprover
      : deactivateUserApprover;
    await mutationName({
      variables: {
        id: projectUserId || userRecord?.id,
        data: map(replacedApproverData, (record) => omit(record, ['id'])),
      },
    });
  };

  return (
    <div id="deactive-user-modal">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        onCancel={() => handleCancel()}
        footer={null}
        closable={false}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('deactive-user-modal')
        }
      >
        <div className="title">
          Checklist where <b>{userRecord?.name}</b> is Approver
        </div>
        {isDesktopViewport ? (
          <>
            <CommonTable
              loadingData={loading}
              columns={columns}
              data={userListData || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey={(obj) => obj?.id}
            />
          </>
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={userListData?.length}
            skeletonRows={columns?.length - 2}
          >
            {map(userListData, (user, index) => {
              return (
                <CommonCard key={user?.id}>
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">{index + 1}.</div>
                    <div>
                      <div className="card-header fw-medium">
                        <span>
                          <EllipsisText text={user?.projectName} />
                        </span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">Checklist:</span>
                          <EllipsisText
                            className="text-secondary"
                            text={user?.projectEqcTypeName}
                          />
                        </div>
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">Stage:</span>
                          <EllipsisText
                            className="text-secondary"
                            text={user?.name}
                          />
                        </div>
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">Approver:</span>
                          {getApproverName('', user)}
                        </div>
                      </div>
                    </div>
                    <span className="d-flex position-absolute user-action-btn">
                      {getActionButtons(user)}
                    </span>
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
        <div className="mt-20 d-flex justify-between align-center">
          <div>
            <p className="mt-10 fw-medium d-flex align-center">
              Note:
              <Avatar
                className={clsx('avatar-color', 'background-primary')}
                size={14}
              />
              Mandatory to replace.
            </p>
          </div>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Popconfirm
              title={`Are you sure you want to  ${projectUserId ? `Delete` : `Deactivate`
                } User?`}
              onConfirm={handleDeactivate}
              okText="Yes"
              cancelText="No"
              disabled={
                !requiredStageIds?.every(
                  (record) =>
                    filter(replacedApproverData, {
                      levelNumber: record?.levelNumber,
                      projectEqcTypeStageId: record?.projectEqcTypeStageId,
                    })?.length > 0,
                )
              }
            >
              <Button
                shape="round"
                type="primary"
                className="save-button ml-5"
                htmlType="submit"
                loading={deactivateUserLoading || deleteProjectUserLoading}
                disabled={
                  !requiredStageIds?.every(
                    (record) =>
                      filter(replacedApproverData, {
                        levelNumber: record?.levelNumber,
                        projectEqcTypeStageId: record?.projectEqcTypeStageId,
                      })?.length > 0,
                  )
                }
              >
                {projectUserId ? `Delete` : `Deactivate`}
              </Button>
            </Popconfirm>
          </div>
        </div>
      </Modal>
      {showReplaceModal && (
        <ReplaceApproverModal
          setShowModal={setShowReplaceModal}
          showModal={showReplaceModal}
          approverData={approverData}
          replacedApproverData={replacedApproverData}
          setReplacedApproverData={setReplacedApproverData}
          setReplacedApprovers={setReplacedApprovers}
          replacedApprovers={replacedApprovers}
          isUpdate={filter(
            replacedApproverData,
            (record) => record?.id === approverData?.id,
          )}
        />
      )}
    </div>
  );
};

export default DeActiveUserModal;
