import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dropdown, Popover, Tooltip } from 'antd';
import clsx from 'clsx';
import { forEach, includes, map, nth, slice } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import {
  AddButton,
  DeleteButton,
  EditButton,
  KebabMenu,
} from '../../../../../../assets/svg';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  PROJECT_ROLES,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { titleCase } from '../../../../../../common/utils';
import CanPerform from '../../../../../../components/CanPerform';
import CommonCard from '../../../../../../components/CommonCard';
import CommonTable from '../../../../../../components/CommonTable';
import HasAccess from '../../../../../../components/HasAccess';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../../../../components/SearchComponent';
import DeActiveUserModal from '../../../../../users/DeActiveUserModal';
import { DELETE_PROJECT_USER } from '../../../../graphql/Mutation';
import { GET_PROJECT_USER_LIST } from '../../../../graphql/Queries';
import AddUserModal from './AddUserModal';
import DeleteUserModal from './DeleteUserModal';

const User = () => {
  const { params: { projectId } } = useRouter();
  const initialProjectUserFilter = {
    skip: 0,
    limit: 10,
    projectId,
    sortBy: { field: 'createdAt', order: 'DESC' },
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [projectUserFilter, setProjectUserFilter] = useState(
    initialProjectUserFilter,
  );
  const [showModal, setShowModal] = useState(false);
  const [projectUserData, setProjectUserData] = useState();
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [mutationId, setMutationId] = useState('');
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [userListData, setUserListData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);

  const [fetchProjectUser, { loading }] = useLazyQuery(GET_PROJECT_USER_LIST, {
    variables: {
      filter: projectUserFilter,
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.projectUserList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.projectUserList?.total,
      };
      if (scrollFlag) {
        const datalist = [...userListData, ...data];
        setUserListData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setUserListData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() { },
  });

  useEffect(() => {
    fetchProjectUser({ variables: projectUserFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    fetchProjectUser({
      variables: { filter: { ...projectUserFilter } },
    });
  };

  const [deleteProjectUser] = useMutation(DELETE_PROJECT_USER);

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setProjectUserFilter({
      ...projectUserFilter,
      skip,
      limit: pagination.pageSize,
      sortBy: { field: 'createdAt', order: 'DESC' },
    });
    fetchProjectUser({
      variables: {
        filter: {
          ...projectUserFilter,
          skip,
          limit: pagination.pageSize,
          sortBy: { field: 'createdAt', order: 'DESC' },
        },
      },
    });
  };

  const handleAddEdit = () => {
    setShowModal(true);
  };
  const getAssignedEqcType = (record) => {
    const fetchName = [];

    if (record?.length !== 0) {
      forEach(record, (o) => {
        fetchName.push(o?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }

    return '-';
  };

  const getActionButtons = (record) => {
    const handleEdit = () => {
      const data = {
        ...record,
        instructionAccess: includes(record?.access, ACCESS_TYPE.INSTRUCTION),
      };
      setProjectUserData(data);
      handleAddEdit();
    };
    const handleDelete = () => {
      setTitle('Project User');
      setName(record?.user?.name);
      setMutationId(record?.id);
      if (record?.assignedAsApprover) {
        setProjectUserData(record);
        setShowDeactivateModal(true);
      } else {
        setDeleteModal(true);
      }
    };
    return (
      <CanPerform
        action={ALLOWED_ACTION_KEYS.ADD_PROJECT_USER}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        {isDesktopViewport ? (
          <>
            <Tooltip title="Edit">
              <Button
                shape="round"
                icon={<EditButton />}
                className="b-0"
                onClick={handleEdit}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                shape="round"
                icon={<DeleteButton />}
                className="b-0"
                onClick={handleDelete}
              />
            </Tooltip>
          </>
        ) : (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'Edit',
                  label: <span onClick={handleEdit}>Edit</span>,
                },
                {
                  type: 'divider',
                },
                {
                  key: 'Delete',
                  label: <span onClick={handleDelete} className="text-danger">Delete</span>,
                },
              ],
            }}
            trigger={['click']}
          >
            <KebabMenu />
          </Dropdown>
        )}
      </CanPerform>
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{projectUserFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'NAME',
      key: 'name',
      render: (record) => {
        return (
          <Popover
            placement="topLeft"
            content={
              <div>
                <b>Email </b>: {record?.user?.email}
              </div>
            }
            getPopupContainer={(triggerNode) => triggerNode?.parentElement}
          >
            <div>{record?.user?.name}</div>
          </Popover>
        );
      },
    },
    {
      title: 'ROLE',
      key: 'role',
      render: (record) => {
        return titleCase(PROJECT_ROLES[record?.roles]);
      },
    },
    ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
      ? [
        {
          title: 'ASSIGNED CHECKLIST',
          key: 'assignedEqc',
          render: (text, record) =>
            getAssignedEqcType(record?.projectUserEqcTypes),
        },
      ]
      : []),
    ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
      ? [
        {
          title: 'ASSIGNED RFI',
          key: 'assignedEqc',
          render: (text, record) =>
            getAssignedEqcType(record?.projectUserRFIEqcTypes),
        },
      ]
      : []),
    {
      key: 'actions',
      align: 'right',
      render: (record) => getActionButtons(record),
    },
  ];

  const onSearchChange = async (value) => {
    setProjectUserFilter({ ...projectUserFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchProjectUser({
      variables: { filter: { ...projectUserFilter, skip: 0, search: value } },
    });
  };
  const handleRefetch = () => {
    fetchProjectUser({
      variables: {
        filter: {
          ...projectUserFilter,
          skip: userListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' },
        },
      },
    });
  };
  const handleRefetchAfterDelete = () => {
    const newSkip =
      userListData?.length === 1
        ? Math.max(0, projectUserFilter?.skip - paginationProp?.pageSize)
        : projectUserFilter?.skip;
    setProjectUserFilter({
      ...projectUserFilter,
      skip: newSkip,
      limit: DEFAULT_PAGE_SIZE,
      sortBy: { field: 'createdAt', order: 'DESC' },
    });
    fetchProjectUser({
      variables: {
        filter: {
          ...projectUserFilter,
          skip: newSkip,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' },
        },
      },
    });
  };

  return (
    <>
      {showModal && (
        <AddUserModal
          showModal={showModal}
          setShowModal={setShowModal}
          projectUserData={projectUserData}
          isUpdate={!!projectUserData}
          setProjectUserData={setProjectUserData}
          refetchUserData={refetchUserDetails}
        />
      )}
      {showDeactivateModal && (
        <DeActiveUserModal
          showModal={showDeactivateModal}
          setShowModal={setShowDeactivateModal}
          userRecord={projectUserData?.user}
          projectId={projectId}
          projectUserId={projectUserData?.id}
          refetchUserDetails={refetchUserDetails}
          setUserData={setProjectUserData}
        />
      )}
      {deleteModal && (
        <DeleteUserModal
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          title={title}
          name={name}
          deleteProjectUser={deleteProjectUser}
          mutationId={mutationId}
          setMutationId={setMutationId}
          fetchProjectUser={handleRefetchAfterDelete}
        />
      )}
      <div className="project-tab-details">
        <div
          className={`project-tab-details-header ${isDesktopViewport ? 'd-flex justify-between align-center' : ''
            }`}
        >
          <h2>User Details</h2>
          <div className="d-flex align-center">
            <SearchComponent
              className="search-component width-percent-100 mr-10"
              getData={onSearchChange}
            />
            <CanPerform
              action={ALLOWED_ACTION_KEYS.ADD_PROJECT_USER}
              type={ALLOWED_ACTION_TYPE.BOTH}
            >
              <Button
                shape="round"
                type="primary"
                className="d-flex align-center"
                icon={
                  <AddButton
                    width="25px"
                    className={clsx(isDesktopViewport && 'mr-5')}
                  />
                }
                onClick={() => {
                  handleAddEdit();
                }}
              >
                {isDesktopViewport && 'Add'}
              </Button>
            </CanPerform>
          </div>
        </div>
        {isDesktopViewport ? (
          <CommonTable
            columns={columns}
            data={userListData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={item => item?.key ?? item?.id}
            loading={loading}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={userListData?.length}
            skeletonRows={columns?.length - 3}
          >
            {map(userListData, (user, index) => {
              return (
                <CommonCard key={user?.id}>
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">{index + 1}.</div>
                    <div>
                      <div
                        id="user-details-card"
                        className="card-header fw-medium"
                      >
                        <Popover
                          placement="topLeft"
                          content={
                            <div>
                              <b>Email </b>: {user?.user?.email}
                            </div>
                          }
                          getPopupContainer={(triggerNode) =>
                            triggerNode?.parentElement
                          }
                        >
                          <span className="text-break">{user?.user?.name}</span>
                        </Popover>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Role:</span>
                          {titleCase(PROJECT_ROLES[user?.roles])}
                        </div>
                        <div>
                          <span className="fw-medium mr-5">
                            Assigned Checklist:
                          </span>
                          <span className="text-break">
                            {getAssignedEqcType(user?.projectUserEqcTypes)}
                          </span>
                        </div>
                        <div>
                          <span className="fw-medium mr-5">Assigned RFI:</span>
                          <span className="text-break">
                            {getAssignedEqcType(user?.projectUserRFIEqcTypes)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="d-flex position-absolute user-action-btn">
                      {getActionButtons(user)}
                    </span>
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default User;
