import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import { includes } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../../../../../../AppContext';
import {
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import useRouter from '../../../../../../common/useRouter';
import { PUBLISH_PROJECT_RFI } from '../../../../graphql/Mutation';

const RfiGoLiveModal = (props) => {
  const {
    showModal,
    setShowModal,
    RFIData,
    handleGoBack,
    setIsEditable,
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { params: { projectId } } = useRouter();

  const handleCancel = () => {
    setShowModal(false);
  };

  const [publishProjectRFI, { loading }] = useMutation(PUBLISH_PROJECT_RFI, {
    onCompleted() {
      Event(GA_EVENT.PUBLISH_PROJECT_RFI, {
        label: GA_LABEL.PUBLISH_PROJECT_RFI,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_rfi_id: RFIData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setShowModal(false);
      setIsEditable(false);
      handleGoBack();
    },
    onError() { },
  });

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        destroyOnClose
      >
        <h2 className="mb-15">
          {!includes(
            [
              PROJECT_CHECKLIST_STATUS.PUBLISHED,
              PROJECT_CHECKLIST_STATUS.ARCHIVED,
            ],
            RFIData?.projectRfi?.status,
          )
            ? `Go Live`
            : 'CAUTION'}
        </h2>
        <p>
          {!includes(
            [
              PROJECT_CHECKLIST_STATUS.PUBLISHED,
              PROJECT_CHECKLIST_STATUS.ARCHIVED,
            ],
            RFIData?.projectRfi?.status,
          )
            ? `This RFI format will be applicable for entire project checklist. Are
          you sure you want to go live?`
            : `Once saved, previous version will no longer be available. Are you sure?`}
        </p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button mr-10"
            onClick={() => setShowModal(false)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            loading={loading}
            onClick={() => {
              publishProjectRFI({
                variables: {
                  id: RFIData?.id,
                },
              });
            }}
          >
            {!includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              RFIData?.projectRfi?.status,
            )
              ? `Go Live`
              : `Confirm`}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RfiGoLiveModal;
