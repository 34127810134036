import { Card, Skeleton } from 'antd';
import React from 'react';

const CommonCard = (props) => {
  const { children, loading, skeletonRows = 5, ...rest } = props;
  return (
    <Card {...rest} >
      {loading ? (
        <Skeleton active paragraph={{ rows: skeletonRows }} />
      ) : (
        children
      )}
    </Card>
  );
};

export default CommonCard;
