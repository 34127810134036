import { Button, Form, Modal } from 'antd';
import { filter, find, includes, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { formValidatorRules } from '../../../common/utils';
import CommonSelect from '../../../components/CommonSelect';

const { required } = formValidatorRules;

const AddProjectAgencyModal = (props) => {
  const {
    showModal,
    setShowModal,
    data,
    handleModalSubmit,
    selectedUsers,
    onPopupScroll,
    onSearch,
    onClear,
    showContacts = false,
    contacts = [],
    setShowContacts,
  } = props;
  const [form] = Form.useForm();
  const [agencyContacts, setAgencyContacts] = useState([]);
  const handleCancel = () => {
    setShowModal(false);
    if (setShowContacts) {
      setShowContacts(false);
    }
    form.resetFields();
  };

  useEffect(() => {
    if (selectedUsers?.length > 0) {
      form.setFieldsValue({
        contactIds: find(selectedUsers, {
          id: contacts?.id || contacts?.[0]?.id,
        })?.contactIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  useEffect(() => {
    if (contacts?.projectAgencyContacts) {
      setAgencyContacts(contacts?.agency?.contacts);
    } else {
      setAgencyContacts(contacts?.contacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  const submitForm = (values) => {
    handleModalSubmit(values, contacts?.id);
    if (setShowContacts) {
      setShowContacts(false);
    }
    form.resetFields();
  };

  return (
    <Modal
      maskClosable={false}
      centered
      open={showModal}
      footer={null}
      closable={false}
    >
      <h2 className="mb-15">{showContacts ? `Add Contact` : `Add Agency`}</h2>
      <Form form={form} layout="vertical" onFinish={submitForm}>
        {showContacts ? (
          <Form.Item name="contactIds" label="Contact" rules={[required]}>
            <CommonSelect
              className="mr-3"
              placeholder="Select Contacts"
              allowClear
              mode="multiple"
              onPopupScroll={onPopupScroll}
              onSearch={onSearch}
              onClear={onClear}
              onBlur={onClear}
              onChange={onClear}
              filterOption={false}

              options={[
                ...map(agencyContacts, ({ id, name }) => {
                  return {
                    key: `contact-${id}`,
                    value: id,
                    label: name,
                  }
                }),
              ]}
            />
          </Form.Item>
        ) : (
          <Form.Item name="agency" label="Agency" rules={[required]}>
            <CommonSelect
              className="mr-3"
              placeholder="Select Agency"
              allowClear
              mode="multiple"
              onPopupScroll={onPopupScroll}
              onSearch={onSearch}
              onClear={onClear}
              onBlur={onClear}
              onChange={onClear}
              filterOption={false}
              options={[
                ...map(
                  filter(
                    data,
                    ({ id }) => !includes(map(selectedUsers, 'id'), id),
                  ),
                  (record) => {
                    return {
                      key: `agency-${record?.id}`,
                      value: JSON.stringify(record),
                      label: record?.name,
                    }
                  }),
              ]}
            />
          </Form.Item>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            Add
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddProjectAgencyModal;
