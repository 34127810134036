import { useMutation } from '@apollo/client';
import { Button, Form, Modal } from 'antd';
import { filter, isEmpty, map, omit } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import {
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  STAGE_STATUS,
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import useRouter from '../../common/useRouter';
import { formValidatorRules, titleCase } from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import { UPDATE_APPROVAL_STATUS } from './graphql/Mutation';

const { required } = formValidatorRules;

const ApproveModal = (props) => {
  const {
    showModal,
    setShowModal,
    data,
    title,
    status,
    commentData,
    additionalComment,
  } = props;
  const [form] = Form.useForm();
  const [showApproverModal, setShowApproverModal] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedApprovers, setSelectedApprovers] = useState([]);
  const { navigate, params: { token } } = useRouter()
  const handleCancel = () => {
    setShowModal(false);
  };
  const approversData = filter(
    data?.getEqcStageHistoryWithoutAuth?.projectEqcTypeStage
      ?.projectEqcTypeStageApprovals,
    (record) =>
      record?.levelNumber ===
      data?.getEqcStageHistoryWithoutAuth?.levelNumber + 1,
  );
  const [updateApprovalStatus, { loading }] = useMutation(
    UPDATE_APPROVAL_STATUS,
    {
      onError() { },
      onCompleted() {
        setShowModal(false);
        const updatedAt = moment().utc().format();
        Event(GA_EVENT.APPROVE_EQC, {
          label: GA_LABEL.APPROVE_EQC,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          status,
        });
        navigate(ROUTES.APPROVER_SUCCESS, {
          status: status === STAGE_STATUS.REDO ? 'Redo' : 'Approve',
          name: data?.getEqcStageHistoryWithoutAuth?.eqc?.eqcName,
          updatedAt,
        });
      },
    },
  );

  const updateStatus = () => {
    const mutationData = {
      stageHistoryId: Number(data?.getEqcStageHistoryWithoutAuth?.id),
      status,
      commentData,
    };
    if (!isSelectedAll && selectedApprovers?.length > 0) {
      mutationData.approverNotifyData = map(selectedApprovers, (approver) => ({
        id: Number(approver?.value),
        type: approver?.['data-type'],
      }));
    }
    if (isSelectedAll) {
      mutationData.approverNotifyData = map(approversData, (approver) => ({
        id: Number(
          approver?.externalApproverId || approver?.internalApproverId,
        ),
        type: approver?.externalApproverId ? 'external' : 'internal',
      }));
    }

    if (additionalComment?.remark) {
      mutationData.additionalComment = additionalComment;
    }
    if (isEmpty(commentData?.eqcStage)) {
      mutationData.commentData = omit(mutationData.commentData, ['eqcStage']);
    }
    if (isEmpty(commentData?.eqcStageItems)) {
      mutationData.commentData = omit(mutationData.commentData, [
        'eqcStageItems',
      ]);
    }

    updateApprovalStatus({
      variables: {
        data: {
          ...mutationData,
          token,
        },
      },
    });
  };

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        closable={false}
        footer={null}
        width={350}
        wrapClassName="approval-confirm-modal"
      >
        <div className="title">{`${title} EQC STAGE`}</div>
        <div className="description mb-16">{`Are you sure you want to ${title.toLowerCase()} this EQC Stage?`}</div>
        <div>
          <Button shape="round" className="mr-10" onClick={handleCancel}>
            No
          </Button>
          <Button
            shape="round"
            htmlType="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              if (
                approversData?.length > 0 &&
                status === STAGE_STATUS.APPROVED
              ) {
                setShowApproverModal(true);
                setShowModal(false);
              } else {
                updateStatus();
              }
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
      <Modal
        maskClosable={false}
        centered
        open={showApproverModal}
        closable={false}
        footer={null}
        wrapClassName="approval-selection-modal"
      >
        <Form
          form={form}
          layout="vertical"
          className="stage-config"
          onFinish={updateStatus}
        >
          <Form.Item
            rules={[required]}
            name="approvers"
            label={
              <div className="d-flex justify-between width-percent-100">
                <span>Select approvers you want to notify</span>
                {isSelectedAll && (
                  <span
                    className="text-primary pointer"
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      setIsSelectedAll(false);
                      form.setFieldsValue({
                        approvers: [],
                      });
                    }}
                  >
                    Deselect All
                  </span>
                )}
              </div>
            }
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectedAll(true);
                return ['all'];
              }
              return value;
            }}
          >
            <CommonSelect
              mode="multiple"
              disabled={isSelectedAll}
              className="mr-3"
              placeholder="Select Approvers"
              allowClear
              onChange={(_, record) => {
                setSelectedApprovers(record);
              }}
              optionFilterProp="children"
              options={[
                {
                  key: "approver-select-all",
                  value: 'all',
                  label: 'Select All',
                },
                ...approversData.map((record) => {
                  return {
                    key: record?.externalApproverId || record?.internalApproverId,
                    value: record?.externalApproverId || record?.internalApproverId,
                    label: titleCase(
                      record?.externalApprover?.name ||
                      record?.internalApprover?.user?.name,
                    ),
                    'data-type': record?.externalApproverId ? 'external' : 'internal',
                  }
                }),
              ]}
            />
          </Form.Item>
          <Form.Item>
            <div className="d-flex justify-end">
              <Button
                shape="round"
                className="mr-10"
                onClick={() => setShowApproverModal(false)}
              >
                Cancel
              </Button>
              <Button
                shape="round"
                htmlType="submit"
                type="primary"
                loading={loading}
              >
                Done
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ApproveModal;
