import { useQuery } from '@apollo/client';
import Gleap from 'gleap';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import LoaderComponent from '../../components/LoaderComponent';
import { LOGOUT_USER } from './graphql/Queries';

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  const { loading, error, data } = useQuery(LOGOUT_USER, {
    fetchPolicy: 'network-only',
    onError() { },
  });

  Gleap.clearIdentity();

  if (loading) return <LoaderComponent />;

  if (error) {
    dispatch({ type: 'LOGOUT' });
    // eslint-disable-next-line no-undef
    window.location = '/login';
    return null;
  }

  if (data) {
    dispatch({ type: 'LOGOUT' });
    // eslint-disable-next-line no-undef
    window.location = '/login';
    return null;
  }

  return null;
};

export default Logout;
