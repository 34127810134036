import { useMutation } from '@apollo/client';
import { Button, Col, Divider, Form, Input, Row, Switch } from 'antd';
import clsx from 'clsx';
import { filter, groupBy, includes, map, max } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../../../../AppContext';
import {
  ADDONS,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
  REQUEST_FEATURE_UPGRADE_KEYS,
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../../../common/utils';
import CommonDropdown from '../../../../../../../../components/CommonDropdown';
import UpgradeModal from '../../../../../../../../components/UpgradeModal';
import { UPDATE_PROJECT_EQC_STAGE_APPROVERS } from '../../../../../../graphql/Mutation';
import {
  GET_EXTERNAL_USERS_LIST,
  GET_INTERNAL_USERS_LIST,
} from '../../../../../../graphql/Queries';

const { characterWithoutWhiteSpace } = formValidatorRules;
const AddApprovalModal = (props) => {
  const {
    isUpdate,
    stageData,
    eqcTypeId,
    eqcTypeData,
    isEditable,
    refetchRef,
  } = props;
  const { getCurrentUser, getTenantUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const tenantUser = getTenantUser();

  const numberOfAllowedLevel = Array.from(
    { length: stageData?.type === ADDONS?.RFI ? 1 : 3 },
    (_, i) => i + 1,
  );

  const approversByLevel = groupBy(
    stageData?.projectEqcTypeStageApprovals,
    (b) => {
      return b?.levelNumber;
    },
  );
  const [form] = Form.useForm();
  const {
    params: { projectId },
  } = useRouter();
  const [selectedAllInternalIndex, setSelectedAllInternalIndex] = useState([]);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [featureKey, setFeatureKey] = useState();
  const [selectedAllExternalIndex, setSelectedAllExternalIndex] = useState([]);
  const [needApproval, setNeedApproval] = useState(false);
  const [allowedLevels, setAllowedLevels] = useState([1]);
  const [levelsToBeVisible, setLevelsToBeVisible] = useState([1]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [updateProjectEqcTypeStageApprovers, { loading: updateLoading }] =
    useMutation(UPDATE_PROJECT_EQC_STAGE_APPROVERS, {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_PROJECT_CHECKLIST_STAGE, {
          label: GA_LABEL.EDIT_PROJECT_CHECKLIST_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        refetchRef?.current?.refetchStageDetails();
      },
    });

  const onSubmitFinish = async (formValues) => {
    const allowedApprovers = filter(formValues?.approvers, (_, index) =>
      includes(allowedLevels, index),
    );
    const newFormValues = map(allowedApprovers, (record, index) => ({
      ...record,
      levelNumber: index + 1,
      internalApproverIds: includes(selectedAllInternalIndex, index + 1)
        ? []
        : record?.internalApproverIds,
      externalApproverIds: includes(selectedAllExternalIndex, index + 1)
        ? []
        : record?.externalApproverIds,
      selectedAllInternalApprovers: includes(
        selectedAllInternalIndex,
        index + 1,
      ),
      selectedAllExternalApprovers: includes(
        selectedAllExternalIndex,
        index + 1,
      ),
    }));

    const variables = {
      data: {
        approvalLevelData: newFormValues,
        projectEqcTypeId: eqcTypeId,
        needApproval: formValues?.needApproval,
      },
      id: stageData?.id,
    };
    try {
      await updateProjectEqcTypeStageApprovers({
        variables: { ...variables },
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (stageData) {
      setNeedApproval(stageData?.needApproval);
      form.setFieldsValue({
        needApproval: stageData?.needApproval,
      });
      const groupData = groupBy(
        stageData?.projectEqcTypeStageApprovals,
        (b) => {
          return b?.levelNumber;
        },
      );
      if (stageData?.projectEqcTypeStageApprovals?.length > 0) {
        const maxLevelKeys = Object?.keys(groupData)?.map((e) => Number(e));
        const maxLevel = max(maxLevelKeys);
        setAllowedLevels(maxLevelKeys);
        if (maxLevel < numberOfAllowedLevel?.length) {
          setLevelsToBeVisible([...maxLevelKeys, maxLevel + 1]);
        } else {
          setLevelsToBeVisible(maxLevelKeys);
        }
      }
      map(
        Object?.keys(groupData)?.map((e) => Number(e)),
        (id) => {
          form.setFieldsValue({
            [`needLevel${id}Approver`]: true,
          });
        },
      );
      for (let index = 1; index <= 3; index += 1) {
        form.setFieldsValue({
          approvers: {
            [index]: {
              externalApproverIds: filter(
                map(
                  groupData?.[index],
                  (element) => element?.externalApproverId,
                ),
                (id) => id,
              ),
              internalApproverIds: filter(
                map(
                  groupData?.[index],
                  (element) => element?.internalApproverId,
                ),
                (id) => id,
              ),
              levelName: groupData?.[index]?.[0]?.levelName,
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageData]);

  const handleInternalDeselect = (index) => {
    form.setFieldsValue({
      approvers: { [index]: { internalApproverIds: [] } },
    });
  };

  useEffect(() => {
    if (selectedAllInternalIndex?.length > 0) {
      map(selectedAllInternalIndex, (index) => {
        form.setFieldsValue({
          approvers: { [index]: { internalApproverIds: ['all'] } },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAllInternalIndex]);

  const handleExternalDeselect = (index) => {
    form.setFieldsValue({
      approvers: { [index]: { externalApproverIds: [] } },
    });
  };
  useEffect(() => {
    if (selectedAllExternalIndex?.length > 0) {
      map(selectedAllExternalIndex, (index) => {
        form.setFieldsValue({
          approvers: { [index]: { externalApproverIds: ['all'] } },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAllExternalIndex]);

  return (
    <div className="add-approver-modal">
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmitFinish}
        disabled={eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED}
      >
        <div>
          <Row
            justify="space-between notification-row"
            align="middle"
            gutter={{ xs: 0, sm: 30 }}
          >
            <Col span={18}>
              <div className="title">Approval required</div>
              <div className="description">
                Inspector has to sent for approval before passing the inspection
                stage
              </div>
            </Col>
            <Col span={4}>
              <div className="d-flex justify-center align-center">
                <Form.Item name="needApproval" valuePropName="checked">
                  <Switch
                    disabled={!isEditable}
                    onChange={(e) => {
                      if (
                        includes(
                          tenantUser?.tenant?.addOn,
                          ADDONS?.MULTI_LEVEL_APPROVAL,
                        )
                      ) {
                        setNeedApproval(e);
                        form.setFieldsValue({
                          needLevel1Approver: true,
                        });
                      } else if (e && !stageData?.needApproval) {
                        form.setFieldsValue({
                          needApproval: false,
                        });
                        setShowUpgradeModal(true);
                        setFeatureKey(
                          REQUEST_FEATURE_UPGRADE_KEYS.STAGE_APPROVAL,
                        );
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
        {needApproval && (
          <div
            className={clsx(
              'add-approval-form mt-24',
              isDropdownVisible && 'hide-overflow',
            )}
          >
            {map(numberOfAllowedLevel, (index) => {
              return (
                <div key={index} hidden={!includes(levelsToBeVisible, index)}>
                  <div className="d-flex align-center mb-10">
                    <div className="title mr-10">{`Level ${index}`} </div>
                    <Form.Item
                      name={`needLevel${index}Approver`}
                      className="mb-0"
                      valuePropName="checked"
                    >
                      <Switch
                        disabled={
                          includes(allowedLevels, index + 1) ||
                          !includes(allowedLevels, index - 1) ||
                          !isEditable
                        }
                        onClick={(e) => {
                          if (e) {
                            if (
                              (includes(
                                Object.keys(approversByLevel),
                                String(index),
                              ) ||
                                index <=
                                  tenantUser?.tenant?.maxApprovalLevel) &&
                              includes(
                                tenantUser?.tenant?.addOn,
                                ADDONS?.MULTI_LEVEL_APPROVAL,
                              )
                            ) {
                              setAllowedLevels((prev) => [...prev, index]);
                            } else if (
                              !includes(
                                Object.keys(approversByLevel),
                                String(index),
                              )
                            ) {
                              form.setFieldsValue({
                                [`needLevel${index}Approver`]: false,
                              });
                              setFeatureKey(
                                REQUEST_FEATURE_UPGRADE_KEYS.STAGE_APPROVAL_MAX_LEVEL,
                              );
                              setShowUpgradeModal(true);
                            }
                          } else {
                            setAllowedLevels(
                              filter(allowedLevels, (level) => level !== index),
                            );
                          }
                        }}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item
                    name={['approvers', index, 'levelName']}
                    dependencies={[`needLevel${index}Approver`]}
                    rules={[
                      characterWithoutWhiteSpace,
                      {
                        required: true && includes(allowedLevels, index),
                        message: 'Missing Level name',
                      },
                      {
                        max: includes(allowedLevels, index) && 30,
                        message: 'Name cannot be more than 30 characters',
                      },
                    ]}
                    label="Name"
                    initialValue={`Level ${index}`}
                  >
                    <Input
                      disabled={!includes(allowedLevels, index) || !isEditable}
                      placeholder="Level Name"
                    />
                  </Form.Item>

                  <Form.Item
                    className="mt-10"
                    name={['approvers', index, 'internalApproverIds']}
                    dependencies={[
                      ['approvers', index, 'externalApproverIds'],
                      [`needLevel${index}Approver`],
                    ]}
                    rules={[
                      ({ getFieldValue }) => ({
                        required:
                          !getFieldValue([
                            'approvers',
                            index,
                            'externalApproverIds',
                          ])?.length && includes(allowedLevels, index),
                        message: 'Please select approver',
                        type: 'array',
                      }),
                    ]}
                    label={
                      <div className="d-flex justify-between width-percent-100">
                        <span>Internal Approvers</span>
                        {includes(selectedAllInternalIndex, index) && (
                          <span
                            className="text-primary pointer"
                            role="button"
                            tabIndex="0"
                            onClick={() => {
                              setSelectedAllInternalIndex(
                                filter(
                                  selectedAllInternalIndex,
                                  (i) => i !== index,
                                ),
                              );
                              handleInternalDeselect(index);
                            }}
                          >
                            Deselect All
                          </span>
                        )}
                      </div>
                    }
                    normalize={(value) => {
                      if (value?.length > 0) {
                        if (index + 1 <= numberOfAllowedLevel?.length) {
                          setLevelsToBeVisible((prev) => [...prev, index + 1]);
                        }
                      }
                      if (value.includes('all')) {
                        setSelectedAllInternalIndex((prev) => [...prev, index]);
                        setIsDropdownVisible(false);
                        return ['all'];
                      }
                      return value;
                    }}
                  >
                    <CommonDropdown
                      onDropdownVisibleChange={(e) => setIsDropdownVisible(e)}
                      mode="multiple"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      placeholder="Select Internal Approvers"
                      query={GET_INTERNAL_USERS_LIST}
                      variables={{
                        filter: {
                          projectId,
                          projectEqcTypeStageId: stageData?.id,
                        },
                      }}
                      responsePath="internalApproverDropdownList.data"
                      valuePath="id"
                      labelPath="user.name"
                      optionKey="internal-approver"
                      fetchPolicy="network-only"
                      disabled={
                        includes(selectedAllInternalIndex, index) ||
                        !includes(allowedLevels, index) ||
                        !isEditable
                      }
                      isSelectedAll={includes(selectedAllInternalIndex, index)}
                      hasSelectAll
                      getPopupContainer={() =>
                        // eslint-disable-next-line no-undef
                        document.querySelector(`.add-approval-form`)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name={['approvers', index, 'externalApproverIds']}
                    dependencies={[
                      ['approvers', index, 'internalApproverIds'],
                      [`needLevel${index}Approver`],
                    ]}
                    rules={[
                      ({ getFieldValue }) => ({
                        required:
                          !getFieldValue([
                            'approvers',
                            index,
                            'internalApproverIds',
                          ])?.length && includes(allowedLevels, index),
                        message: 'Please select approver',
                        type: 'array',
                      }),
                    ]}
                    label={
                      <div className="d-flex justify-between width-percent-100">
                        <span>External Approvers</span>
                        {includes(selectedAllExternalIndex, index) && (
                          <span
                            className="text-primary pointer"
                            role="button"
                            tabIndex="0"
                            onClick={() => {
                              setSelectedAllExternalIndex(
                                filter(
                                  selectedAllExternalIndex,
                                  (i) => i !== index,
                                ),
                              );
                              handleExternalDeselect(index);
                            }}
                          >
                            Deselect All
                          </span>
                        )}
                      </div>
                    }
                    normalize={(value) => {
                      if (value?.length > 0) {
                        if (index + 1 <= numberOfAllowedLevel?.length) {
                          setLevelsToBeVisible((prev) => [...prev, index + 1]);
                        }
                      }
                      if (value.includes('all')) {
                        setSelectedAllExternalIndex((prev) => [...prev, index]);
                        setIsDropdownVisible(false);
                        return ['all'];
                      }
                      return value;
                    }}
                  >
                    <CommonDropdown
                      onDropdownVisibleChange={(e) => setIsDropdownVisible(e)}
                      mode="multiple"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      placeholder="Select External Approvers"
                      query={GET_EXTERNAL_USERS_LIST}
                      variables={{
                        filter: {
                          projectId,
                          projectEqcTypeStageId: stageData?.id,
                        },
                      }}
                      responsePath="externalApproverDropdownList.data"
                      valuePath="id"
                      labelPath={['name', 'agency.name']}
                      optionKey="external-approver"
                      fetchPolicy="network-only"
                      disabled={
                        includes(selectedAllExternalIndex, index) ||
                        !includes(allowedLevels, index) ||
                        !isEditable
                      }
                      isSelectedAll={includes(selectedAllExternalIndex, index)}
                      hasSelectAll
                      getPopupContainer={() =>
                        // eslint-disable-next-line no-undef
                        document.querySelector('.add-approval-form')
                      }
                    />
                  </Form.Item>
                  {includes(levelsToBeVisible, index + 1) &&
                    numberOfAllowedLevel?.length > index && <Divider dashed />}
                </div>
              );
            })}
          </div>
        )}
      </Form>

      {isEditable && (
        <div className="form-buttons mt-5">
          {eqcTypeData?.status !== PROJECT_CHECKLIST_STATUS?.ARCHIVED && (
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
              onClick={() => form.submit()}
              loading={updateLoading}
            >
              {isUpdate ? 'Save' : 'Add'}
            </Button>
          )}
        </div>
      )}
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          projectId={projectId}
          featureKey={featureKey}
          isAlreadyRequested={
            tenantUser?.tenant?.featureApprovalRequestConfig?.[
              featureKey === REQUEST_FEATURE_UPGRADE_KEYS.STAGE_APPROVAL
                ? 'stageApprovalRequestSent'
                : 'stageApprovalMaxLevelRequestSent'
            ]
          }
        />
      )}
    </div>
  );
};

export default AddApprovalModal;
