import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import { filter, includes, values } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../AppContext';
import countryCodeData from '../../assets/countryCode.json';
import {
  ADD_USER_ROLES,
  GA_EVENT,
  GA_LABEL,
  MOBILE_NUMBER_NOTE,
  REGEX,
  USER_ROLES,
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import {
  formValidatorRules,
  getPhoneCodeFromTimezone,
  titleCase,
} from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import PhoneNumberInputWithCountyCode from '../../components/PhoneNumberInputWithCountyCode';
import { CREATE_USER, UPDATE_USER } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const AddUserModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    userData,
    setUserData,
    refetchUserDataWithInitialValues,
  } = props;
  const { getCurrentUser, getTenantUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const currentTenant = getTenantUser();
  const [showChecklistMakerNote, setShowChecklistMakerNote] = useState(false);
  const [form] = Form.useForm();
  const [createUserMutate, { loading: createUserLoading }] = useMutation(
    CREATE_USER,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_GLOBAL_USER, {
          label: GA_LABEL.ADD_GLOBAL_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setUserData();
        form.resetFields();
        setShowModal(false);
        refetchUserDataWithInitialValues();
      },
    },
  );

  const [updateUserMutate, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_GLOBAL_USER, {
          label: GA_LABEL.EDIT_GLOBAL_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          updated_user_id: userData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setUserData();
        form.resetFields();
        setShowModal(false);
        refetchUserDataWithInitialValues();
      },
    },
  );

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setUserData();
  };

  useMemo(() => {
    if (currentTenant?.tenant?.timeZone && !userData?.countryCode) {
      const phoneCode = getPhoneCodeFromTimezone(
        currentTenant?.tenant?.timeZone,
      );
      form.setFieldsValue({
        countryCode: phoneCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenant?.tenant?.timeZone]);

  const onUserSubmitFinish = async (formValues) => {
    const newFormValues = {
      ...formValues,
      phoneNo: `${formValues?.countryCode}${formValues?.phoneNo}`,
      country: filter(
        countryCodeData,
        (record) => record?.dialCode === formValues?.countryCode,
      )?.[0]?.countryCode?.toUpperCase(),
    };

    const variables = isUpdate
      ? { data: newFormValues, id: userData?.id }
      : newFormValues;

    try {
      if (isUpdate) {
        updateUserMutate({
          variables: { ...variables },
        });
        return;
      }
      createUserMutate({
        variables: { data: { ...variables } },
      });
    } catch (error) {
      return error;
    }
  };

  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };
  const handleSelect = (value) => {
    if (isUpdate) {
      if (
        !includes(userData?.roles, ADD_USER_ROLES.CHECKLIST_MAKER) &&
        value === ADD_USER_ROLES.CHECKLIST_MAKER
      ) {
        setShowChecklistMakerNote(true);
      } else {
        setShowChecklistMakerNote(false);
      }
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      open={showModal}
      confirmLoading={createUserLoading || updateUserLoading}
      onOk={handleAdd}
      className="dialog"
      okText={isUpdate ? 'Save' : 'Add'}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <h2>{isUpdate ? 'Edit user' : 'Add user'}</h2>
      <Form
        form={form}
        initialValues={
          userData
            ? {
                ...userData,
                phoneNo: userData?.phoneNo?.replace(userData?.countryCode, ''),
              }
            : { isActive: true, countryCode: '+91' }
        }
        layout="vertical"
        onFinish={onUserSubmitFinish}
      >
        <Form.Item
          rules={[
            required,
            {
              max: 250,
              message: 'Username cannot be more than 250 characters',
            },
          ]}
          name="name"
          label="Name"
        >
          <Input allowClear placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          rules={[{ required, message: 'Required' }]}
          name="roles"
          label="Roles"
        >
          <CommonSelect
            className="mr-3"
            placeholder="Select Role"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={handleSelect}
            disabled={includes(userData?.roles, USER_ROLES.SYSTEM_ADMIN)}
            options={[
              ...(includes(userData?.roles, USER_ROLES.SYSTEM_ADMIN)
                ? {
                    key: USER_ROLES.SYSTEM_ADMIN,
                    value: USER_ROLES.SYSTEM_ADMIN,
                    label: titleCase(USER_ROLES.SYSTEM_ADMIN),
                  }
                : []),
              ...values(ADD_USER_ROLES).map((role) => {
                return {
                  key: role,
                  value: role,
                  label: titleCase(role),
                };
              }),
            ]}
          />
        </Form.Item>

        {!isUpdate && (
          <Form.Item rules={[required, email]} name="email" label="Email">
            <Input allowClear placeholder="Enter Email" />
          </Form.Item>
        )}
        <Form.Item
          label="Mobile Number"
          name="phoneNo"
          dependencies={['countryCode']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!getFieldValue('countryCode')) {
                  return Promise.reject(
                    new Error('Please Select Country Code'),
                  );
                }
                if (!value) {
                  return Promise.reject(new Error('Enter Mobile Number'));
                }
                if (value && !value.match(REGEX.PHONE)) {
                  return Promise.reject(
                    new Error('should be a valid contact number'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <PhoneNumberInputWithCountyCode />
        </Form.Item>
        <div className="text-danger note-text note-margin ">
          Note : {MOBILE_NUMBER_NOTE}
        </div>
        {showChecklistMakerNote && (
          <div className="text-danger mb-20">
            <b>Caution:</b> Changing role to Checklist Maker will remove this
            user from all the assigned projects
          </div>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
