import { DownOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Empty, Form, Row, Tooltip, message } from 'antd';
import clsx from 'clsx';
import {
  cloneDeep,
  debounce,
  every,
  filter,
  findIndex,
  includes,
  intersection,
  map,
  nth,
  slice,
} from 'lodash';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import { DeleteButton, EditButton } from '../../../assets/svg';
import {
  ACCESS_TYPE,
  ADD_PROJECT_STEPS_KEYS,
  BREAKPOINTS,
  DROPDOWN_LIMIT,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  SCROLL_CONST,
  TAB_KEYS,
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import useRouter from '../../../common/useRouter';
import CommonCard from '../../../components/CommonCard';
import CommonSelect from '../../../components/CommonSelect';
import CommonTable from '../../../components/CommonTable';
import EllipsisText from '../../../components/EllipsisText';
import HasAccess from '../../../components/HasAccess';
import { ONBOARD_PROJECT_AGENCIES } from '../graphql/Mutation';
import {
  GET_ALL_AGENCIES,
  GET_ONBOARDED_PROJECT_AGENCY_LIST,
} from '../graphql/Queries';
import AddProjectAgencyModal from './AddProjectAgencyModal';

let searchDebounce = null;

const AddProjectAgencies = () => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [contacts, setContacts] = useState([]);
  const [existingIds, setExistingIds] = useState([]);
  const [remainingAgencies, setRemainingAgencies] = useState([]);
  const [allAgencies, setAllAgencies] = useState([]);
  const { navigate, params: { projectId } } = useRouter()
  const hasInspectionAccess = HasAccess({ type: ACCESS_TYPE.INSPECTION });
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [fetchAllAgencies, { loading: allAgenciesLoading }] = useLazyQuery(
    GET_ALL_AGENCIES,
    {
      fetchPolicy: 'network-only',
      variables: { filter: { projectId } },
      onCompleted: (res) => {
        const data = res?.agencyDropdownList?.data;
        if (currentPage === 0) {
          setRemainingAgencies(data);
          setAllAgencies(data);
        } else {
          setRemainingAgencies((oldData) => [...oldData, ...data]);
          setAllAgencies((oldData) => [...oldData, ...data]);
        }
        setCurrentPage((page) => page + 1);
        setHasMore(data?.length >= DROPDOWN_LIMIT);
      },
    },
  );
  const { data: existingAgencies, loading: projectAgenciesLoading } = useQuery(
    GET_ONBOARDED_PROJECT_AGENCY_LIST,
    {
      fetchPolicy: 'network-only',
      variables: { filter: { projectId } },
      onCompleted: (res) => {
        const dataWithContactIds = map(
          res?.onboardProjectAgencyList?.data,
          (data) => ({
            ...data,
            contactIds: map(data?.projectAgencyContacts, 'contact.id'),
          }),
        );
        setTableData([...tableData, ...dataWithContactIds]);
      },
    },
  );

  const [onboardProjectUsers, { loading: mutationLoading }] = useMutation(
    ONBOARD_PROJECT_AGENCIES,
    {
      onError() { },
      onCompleted: () => {
        Event(GA_EVENT.ONBOARD_PROJECT_AGENCIES, {
          label: GA_LABEL.ONBOARD_PROJECT_AGENCIES,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        if (hasInspectionAccess) {
          navigate(
            `${ROUTES.ADD_PROJECTS}/${Number(projectId)}/${ADD_PROJECT_STEPS_KEYS.CHECKLISTS
            }`,
          );
        } else {
          navigate(
            `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.INSTRUCTION}`,
          );
        }
      },
    },
  );
  useEffect(() => {
    fetchAllAgencies({
      variables: {
        filter: {
          skip: currentPage * DROPDOWN_LIMIT,
          limit: DROPDOWN_LIMIT,
          projectId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formSubmit = () => {
    if (!tableData?.length > 0) {
      message.destroy();
      return message.error('Add at least one agency!');
    }
    if (!every(tableData, 'contactIds')) {
      message.destroy();
      return message.error('Missing Contact!');
    }
    const data = map(tableData, ({ agencyId, id, contactIds }) => {
      return {
        contactIds,
        agencyId: Number(agencyId) || Number(id),
        projectId,
        projectEqcTypeIds: [],
      };
    });
    onboardProjectUsers({
      variables: { data },
    });
  };

  useEffect(() => {
    if (existingAgencies?.onboardProjectAgencyList?.data?.length) {
      const tempArr = [];
      const contactIds = [];
      const formData = map(
        existingAgencies?.onboardProjectAgencyList?.data,
        (agency) => {
          const agencyIndex = findIndex(
            remainingAgencies,
            (remainingAgency) => agency?.agency?.id === remainingAgency?.id,
          );
          if (agencyIndex !== -1) {
            contactIds.push(remainingAgencies[agencyIndex]?.contacts);
          } else {
            contactIds.push(agency?.agency?.contacts);
          }
          tempArr.push(agency?.agency?.id);
          return {
            agencyId: agency?.agency?.id,
            contactIds: map(
              agency?.projectAgencyContacts,
              ({ contact: { id } }) => id,
            ),
          };
        },
      );
      setContacts(contactIds);
      setExistingIds(tempArr);
      form.setFieldsValue({ projectAgencies: formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingAgencies]);
  const searchQuery = (search) => {
    setSearchText(search);
    setCurrentPage(0);
    fetchAllAgencies({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          skip: 0,
          limit: DROPDOWN_LIMIT,
          search,
          projectId,
        },
      },
    });
  };

  useEffect(() => {
    if (existingIds?.length && allAgencies?.length) {
      const remainingAgency = filter(
        allAgencies,
        (agency) => !includes(existingIds, agency.id),
      );
      setRemainingAgencies(remainingAgency);
    }
  }, [existingIds, allAgencies]);

  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && hasMore && !allAgenciesLoading) {
      fetchAllAgencies({
        variables: {
          filter: {
            skip: currentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            search: searchText,
            projectId,
          },
        },
      });
    }
  };

  const handleSearch = (value) => {
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(value);
  };
  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };

  const handleAddModalSubmit = (values, agencyId) => {
    if (values?.agency) {
      setTableData([
        ...tableData,
        ...map(values?.agency, (agency) => ({
          id: JSON.parse(agency)?.id,
          name: JSON.parse(agency)?.name,
          contacts: JSON.parse(agency)?.contacts,
          contactIds: map(JSON.parse(agency)?.contacts, 'id'),
        })),
      ]);
    }
    if (values?.contactIds) {
      const index = findIndex(tableData, { id: agencyId });
      const data = cloneDeep(tableData);
      data[index].contactIds = values?.contactIds;
      setTableData(data);
    }
    setShowModal(false);
  };

  const EditableContext = createContext(null);
  const EditableRow = ({ index, ...props }) => {
    const [editableForm] = Form.useForm();
    return (
      <Form form={editableForm} component={false}>
        <EditableContext.Provider value={editableForm}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    editable,
    children,
    dataIndex,
    record,
    title,
    ...restProps
  }) => {
    const editableForm = useContext(EditableContext);
    const inputRef = useRef(null);

    useEffect(() => {
      if (isOpen) {
        inputRef?.current?.focus();
        editableForm.setFieldsValue({
          [dataIndex]: record?.contactIds,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const save = async () => {
      try {
        const values = await editableForm.validateFields();

        const index = findIndex(tableData, { id: contacts?.id });
        if (values?.contacts?.length > 0) {
          tableData[index].contactIds = values?.contacts;
        } else {
          delete tableData[index].contactIds;
        }
        setContacts();
        setIsOpen(false);
      } catch (errInfo) {
        // eslint-disable-next-line no-console
        console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;
    if (editable) {
      childNode =
        contacts?.id === record?.id ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <CommonSelect
              className="mr-3"
              inputRef={inputRef}
              placeholder="Select Contacts"
              allowClear
              mode="multiple"
              open={isOpen && contacts?.id === record?.id}
              onPopupScroll={handleScroll}
              onBlur={save}
              onClear={save}
              optionFilterProp="children"
              getPopupContainer={() =>
                // eslint-disable-next-line no-undef
                document.querySelector('.editable-table')
              }
              options={[
                ...map(
                  contacts?.agency?.contacts || contacts?.contacts,
                  ({ id, name }) => {
                    return {
                      key: `contact-${id}`,
                      value: id,
                      label: name,
                    }
                  }),
              ]}
            />
          </Form.Item>
        ) : (
          <div
            className={clsx('editable-cell-value-wrap')}
            onClick={() => {
              setIsOpen(true);
              setContacts(record);
            }}
          >
            {children}
          </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const getActionButtons = (record) => {
    const handleEditModal = (data) => {
      setShowModal(true);
      setShowContacts(true);
      setContacts(data);
    };
    const deleteAgency = (data) => {
      setTableData(filter(tableData, (agency) => agency?.id !== data?.id));
    };
    return (
      <div className="d-flex justify-end">
        {!isDesktopViewport && (
          <Tooltip title="Edit">
            <Button
              shape="round"
              icon={<EditButton />}
              className="edit-button pointer b-0"
              onClick={() => handleEditModal(record)}
            />
          </Tooltip>
        )}
        <Tooltip title="Delete">
          <Button
            className="delete-button pointer b-0"
            shape="round"
            icon={<DeleteButton />}
            onClick={() => deleteAgency(record)}
          />
        </Tooltip>
      </div>
    );
  };

  const getContacts = (record) => {
    const index = findIndex(tableData, { id: record?.id });
    const agencyContacts = map(
      tableData[index]?.contacts || record?.agency?.contacts,
      'id',
    );
    const selectedContactIds = intersection(agencyContacts, record?.contactIds);
    const selectedContacts = filter(
      tableData[index]?.contacts || record?.agency?.contacts,
      (d) => includes(selectedContactIds, d?.id),
    );
    const fetchName = map(selectedContacts, 'name');
    const selectedName = nth(fetchName, 0);
    let children = 'Select Contacts';
    if (fetchName?.length === 1) {
      children = (
        <Tooltip title={selectedName} placement="right">
          <span className="ellipsis-text contact-name">{selectedName}</span>
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      children = (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          <div className="d-flex">
            <div className="ellipsis-text contact-name-with-count mr-5">
              {selectedName}
            </div>
            <div className="contact-count">{`and +${fetchName?.length - 1
              }`}</div>
          </div>
        </Tooltip>
      );
    }
    return isDesktopViewport ? (
      <div
        className={clsx(
          'd-flex justify-between align-center readonly-select',
          !fetchName?.length > 0 && 'placeholder-text readonly-select-with-glow',
        )}
      >
        {children} <DownOutlined />
      </div>
    ) : (
      <>{children}</>
    );
  };
  const defaultColumns = [
    {
      title: (
        <div>
          <span className="required-mark">*</span>
          <span>Agencies</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (name, record) => {
        return (
          <div>
            {record?.projectAgencyContacts ? record?.agency?.name : name}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <span className="required-mark">*</span>
          <span>Contact</span>
        </div>
      ),
      dataIndex: 'contacts',
      key: 'contacts',
      editable: true,
      width: '50%',
      render: (role, record) => {
        return <div>{getContacts(record)}</div>;
      },
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => getActionButtons(record),
    },
  ];

  const columns = defaultColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  return (
    <>
      <div className="header d-flex align-center justify-between mb-20">
        <h2 className="m-0">Agency</h2>
        <div className="eqc-stage-header-buttons d-flex">
          <Button
            shape="round"
            type="primary"
            id="add-btn"
            className="add-button"
            onClick={() => setShowModal(true)}
          >
            Add
          </Button>
        </div>
      </div>
      {showModal && (
        <AddProjectAgencyModal
          showModal={showModal}
          setShowModal={setShowModal}
          data={allAgencies}
          handleModalSubmit={handleAddModalSubmit}
          selectedUsers={tableData}
          onPopupScroll={handleScroll}
          onSearch={handleSearch}
          onClear={handleClear}
          showContacts={showContacts}
          setShowContacts={setShowContacts}
          contacts={contacts}
        />
      )}
      {isDesktopViewport ? (
        <div className="mb-20 editable-table">
          <CommonTable
            components={components}
            data={tableData}
            columns={columns}
            loading={projectAgenciesLoading}
            rowKey="id"
            className="add-project-agency-table"
          />
        </div>
      ) : (
        <div className=" position-relative">
          {tableData.length ? (
            <div className="mb-15 width-percent-100 infinite-data-container">
              {map(tableData, (user, index) => {
                return (
                  <CommonCard key={user?.id}>
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">{index + 1}.</div>
                      <div>
                        <div className="card-header fw-medium">
                          <span>
                            <EllipsisText
                              text={
                                user?.projectAgencyContacts
                                  ? user?.agency?.name
                                  : user?.name
                              }
                            />
                          </span>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15 d-flex align-center">
                            <span className="fw-medium mr-10">Contact:</span>
                            {getContacts(user)}
                          </div>
                        </div>
                      </div>
                      <span className="d-flex position-absolute user-action-btn">
                        {getActionButtons(user)}
                      </span>
                    </div>
                  </CommonCard>
                );
              })}
            </div>
          ) : (
            <Empty description="No user added yet!" className="mb-20" />
          )}
        </div>
      )}
      <Form form={form} layout="vertical" onFinish={formSubmit}>
        <Row justify="end">
          <Col className="form-buttons">
            <Button
              shape="round"
              onClick={() => {
                Event(GA_EVENT.CLICK_PREVIOUS_BUTTON, {
                  label: `While onboard agencies in new project`,
                  // eslint-disable-next-line no-undef
                  pathname: window?.location?.href,
                  project_id: projectId,
                  user_id: currentUser?.id,
                  user_name: currentUser?.name,
                  tenant_id: currentUser?.tenantUser?.tenant?.id,
                  tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
                });
                navigate(
                  `${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.USERS}`,
                );
              }}
              className="cancel-button"
            >
              Previous
            </Button>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              loading={mutationLoading}
            >
              {hasInspectionAccess ? 'Next' : 'Add Project'}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddProjectAgencies;
