import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Popover,
  Row,
  Tag,
  Tooltip,
  message,
} from 'antd';
import clsx from 'clsx';
import { capitalize, map, omit, values } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import {
  EditButton,
  InfoIcon,
  InfoIconDark,
  KebabMenu,
  Timer,
} from '../../../../../../assets/svg';
import {
  AGENCY_TYPE,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  DEFAULTDATETIMEFORMAT,
  DEFAULT_PAGE_SIZE,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL,
  REQUEST_FEATURE_UPGRADE_KEYS,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { removeHistoryStateData } from '../../../../../../common/utils';
import CanPerform from '../../../../../../components/CanPerform';
import CollapsibleFilterWrapper from '../../../../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../../../../components/CommonCard';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import CommonSelect from '../../../../../../components/CommonSelect';
import CommonTable from '../../../../../../components/CommonTable';
import EllipsisText from '../../../../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../../../../components/SearchComponent';
import UpgradeModal from '../../../../../../components/UpgradeModal';
import {
  GET_PROJECT_INSTRUCTION_LIST,
  INSTRUCTION_OTHER_TYPE_LIST,
  INSTRUCTION_TAG_LIST,
  PROJECT_AGENCY_DROPDOWN,
  PROJECT_USER_DROPDOWN,
} from '../../../../graphql/Queries';
import EditModal from './EditModal';

const Instruction = ({ uniqueCode }) => {
  const { navigate, params: { projectId }, location } = useRouter()
  const { getTenantUser, getCurrentProjectDetail } = useContext(AppContext);
  const currentProjectDetails = getCurrentProjectDetail();
  const currentTenant = getTenantUser();
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const instructionFilter = location?.state?.instructionFilter;
  const instructionPagination = location?.state?.instructionPagination;

  const initialProjectInstructionFilter = {
    skip: 0,
    limit: 10,
    projectId,
    sortBy: { field: 'updatedAt', order: 'DESC' },
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(
    instructionPagination || initialPaginationValue,
  );
  const [projectInstructionFilter, setProjectInstructionFilter] = useState(
    instructionFilter || initialProjectInstructionFilter,
  );
  const [showModal, setShowModal] = useState(false);
  const [instructionData, setInstructionData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [instructionListData, setInstructionListData] = useState();
  const [userId, setUserId] = useState(instructionFilter?.userId);
  const [selectedUserRecord, setSelectedUserRecord] = useState(
    location?.state?.selectedUserRecord,
  );
  const [isOverdue, setIsOverdue] = useState(instructionFilter?.isOverdue);
  const [projectAgencyId, setProjectAgencyId] = useState(
    instructionFilter?.projectAgencyId,
  );
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectAgencyRecord, setSelectedAgencyRecord] = useState(
    location?.state?.selectAgencyRecord,
  );
  const [type, setType] = useState(instructionFilter?.type);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [instructionStatus, setInstructionStatus] = useState(
    instructionFilter?.status,
  );
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    if (instructionFilter) {
      setProjectInstructionFilter(instructionFilter);
    }
    if (instructionPagination) {
      setPaginationProp(instructionPagination);
    }
    if (instructionFilter?.userId) {
      setUserId(instructionFilter?.userId);
    }
    if (instructionFilter?.projectAgencyId) {
      setProjectAgencyId(instructionFilter?.projectAgencyId);
    }
    if (instructionFilter?.type) {
      setType(instructionFilter?.type);
    }
    if (instructionFilter?.isOverdue) {
      setIsOverdue(instructionFilter?.isOverdue);
    }
    if (instructionFilter?.status) {
      setInstructionStatus(instructionFilter?.status);
    }
    if (instructionFilter?.tags?.length > 0) {
      setSelectedTags(instructionFilter?.tags);
    }
  }, [instructionFilter, instructionPagination]);

  useEffect(() => {
    setSelectedUserRecord(location?.state?.selectedUserRecord);
  }, [location?.state?.selectedUserRecord]);

  useEffect(() => {
    setSelectedAgencyRecord(location?.state?.selectAgencyRecord);
  }, [location?.state?.selectAgencyRecord]);

  const [fetchProjectInstruction, { loading }] = useLazyQuery(
    GET_PROJECT_INSTRUCTION_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.instructionList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.instructionList?.total,
        };
        if (scrollFlag) {
          const datalist = [...instructionListData, ...data];
          setInstructionListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setInstructionListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() { },
    },
  );

  useEffect(() => {
    fetchProjectInstruction({
      variables: { filter: projectInstructionFilter },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectInstructionFilter]);

  useEffect(() => {
    let newFilter = {
      ...projectInstructionFilter,
      status: instructionStatus,
      tags: selectedTags,
      type,
      projectAgencyId,
      userId,
      skip:
        projectInstructionFilter?.skip !== 0
          ? projectInstructionFilter?.skip
          : 0,
      projectId,
    };
    if (isOverdue) {
      newFilter.isOverdue = isOverdue;
    } else {
      newFilter = omit(newFilter, 'isOverdue');
    }
    setProjectInstructionFilter(newFilter);
    setPaginationProp({
      ...paginationProp,
      current: paginationProp?.current !== 1 ? paginationProp?.current : 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userId,
    projectAgencyId,
    type,
    instructionStatus,
    isOverdue,
    projectId,
    selectedTags,
  ]);

  const handleEdit = (e, record) => {
    e?.stopPropagation?.();
    setInstructionData(record);
    setShowModal(true);
  };
  const getUpdatedBy = (record) => {
    if (record?.status === INS_STATUS_KEYS.RESPONDED) {
      return record?.responder?.name || '-';
    }
    return record?.modifier?.name || record?.creator?.name || '-';
  };
  const getRespondedBy = (record) => {
    return record?.responder?.name || '-';
  };
  const getStatus = (record) => {
    return (
      <Tag className={INS_STATUS_CLASSNAME[record?.status]}>
        {INS_STATUS_LABEL[record?.status]}
      </Tag>
    );
  };
  const getDueDate = (record) => {
    const isOverDue = record?.isOverdue;
    return record?.isNotice || !record?.reminderDate ? (
      '-'
    ) : (
      <div className={`d-flex align-center ${isOverDue ? 'text-danger' : ''}`}>
        <div>
          {moment(record?.reminderDate)
            .tz(currentProjectDetails?.timeZone)
            .format(DATETIMEWITHDIVIDE)}
        </div>
        {isOverDue && (
          <Tooltip title="Overdue">
            <div className="ml-5 d-flex align-center">
              <Timer height="18" width="18" />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };
  const getTypeName = (record) => {
    return record?.type || '-';
  };

  const getTagsHeader = () => {
    return (
      <Popover
        destroyTooltipOnHide
        getPopupContainer={() =>
          // eslint-disable-next-line no-undef
          document.querySelector('.project-tab-details')
        }
        placement="bottom"
        content={
          <div onClick={(e) => e.stopPropagation()}>
            <h4>Upgrade Require</h4>
            <Button
              type="primary"
              onClick={() => {
                setShowUpgradeModal(true);
              }}
            >
              Click To Upgrade
            </Button>
          </div>
        }
      >
        <InfoIcon
          height={16}
          width={16}
          className="info-icon ml-5"
          onClick={(e) => e.stopPropagation()}
        />
      </Popover>
    );
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return (
          <div className="d-flex align-center">
            <span>{projectInstructionFilter?.skip + index + 1}</span>
            <span className="ml-5 d-flex align-center">
              {record?.status === INS_STATUS_KEYS.NOTICE && (
                <Tooltip title="Notice">
                  <InfoIconDark height="20px" width="20px" />
                </Tooltip>
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (text) => (
        <div>
          <EllipsisText text={text || '-'} />
        </div>
      ),
    },
    {
      title: 'TYPE',
      key: 'type',
      render: (record) => getTypeName(record),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'type' && sortedInfo?.order,
    },
    {
      title: (
        <div className="d-flex align-center tags-col">
          TAGS
          {!currentTenant?.tenant?.featureConfig?.issueTag && getTagsHeader()}
        </div>
      ),
      key: 'tag',
      dataIndex: 'tags',
      render: (tags) => (
        <div className="tags-div">
          {tags?.length > 0 ? tags?.map((tag) => <div key={tag}>{`#${tag}`}</div>) : '-'}
        </div>
      ),
    },
    {
      title: 'AGENCY NAME',
      key: 'agencyName',
      render: (record) => {
        return record?.projectAgency?.agency?.type === AGENCY_TYPE.LOCAL ? (
          <>
            Phone Contact
            <div>{record?.contacts?.[0]?.phoneNo}</div>
          </>
        ) : (
          record?.projectAgency?.agency?.name
        );
      },
    },
    {
      title: 'STATUS',
      key: 'status',
      render: (status, record) => getStatus(record),
    },
    {
      title: 'DUE DATE',
      key: 'reminderDate',
      render: (reminderDate, record) => getDueDate(record),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'reminderDate' && sortedInfo?.order,
    },
    {
      title: 'UPDATED BY',
      key: 'updatedBy',
      render: (record) => getUpdatedBy(record),
    },
    {
      title: 'RESPONDED BY',
      key: 'respondedBy',
      render: (record) => getRespondedBy(record),
    },
    {
      title: 'RAISED AT',
      key: 'createdAt',
      render: (dueDate, record) => {
        return record?.createdAt
          ? moment(`${record?.createdAt}`)
            .tz(currentProjectDetails?.timeZone)
            .format(DATETIMEWITHDIVIDE)
          : '-';
      },
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
    },
    {
      key: 'actions',
      align: 'right',
      render: (record) => {
        return (
          <CanPerform
            action={ALLOWED_ACTION_KEYS.EDIT_INSTRUCTION}
            type={ALLOWED_ACTION_TYPE.PROJECT}
          >
            <Tooltip title="Edit">
              <Button
                shape="round"
                icon={<EditButton />}
                onClick={(e) => handleEdit(e, record)}
                className={clsx(
                  (record?.status === INS_STATUS_KEYS.CLOSED ||
                    record?.status === INS_STATUS_KEYS.NOTICE) &&
                  'disabled-button',
                )}
                disabled={
                  record?.status === INS_STATUS_KEYS.CLOSED ||
                  record?.status === INS_STATUS_KEYS.NOTICE
                }
              />
            </Tooltip>
          </CanPerform>
        );
      },
    },
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    setProjectInstructionFilter({
      ...projectInstructionFilter,
      skip,
      limit: pagination.pageSize,
      sortBy: sorter?.column
        ? {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        }
        : { field: 'createdAt', order: 'DESC' },
    });
  };

  const onSearchChange = async (value) => {
    if (!value) {
      removeHistoryStateData(
        navigate,
        location,
        'instructionFilter',
        'search',
      );
    }
    setPaginationProp(initialPaginationValue);
    setProjectInstructionFilter({
      ...projectInstructionFilter,
      skip: 0,
      search: value,
    });
  };
  const handleRefetch = () => {
    fetchProjectInstruction({
      variables: {
        filter: {
          ...projectInstructionFilter,
          skip: instructionListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' },
        },
      },
    });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      navigate(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.INSTRUCTION}/${record?.id}`,
        {
          navFilter,
          navPagination,
          instructionFilter: projectInstructionFilter,
          instructionPagination: paginationProp,
          selectedUserRecord,
          selectAgencyRecord,
          currentPath: location?.pathname,
        },
      );
    }
  };

  const items = [
    {
      key: 'filter',
      children: <Row
        className="d-flex justify-end filter-search align-center"
        wrap
        gutter={isDesktopViewport ? [10, 10] : [0, 10]}
      >
        <Col span={isDesktopViewport ? null : 12}>
          <Checkbox
            onChange={(event) => {
              setIsOverdue(event?.target?.checked);
              setProjectInstructionFilter({
                ...projectInstructionFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
            }}
            checked={isOverdue}
          >
            Overdue
          </Checkbox>
        </Col>
        {!isDesktopViewport && <Col span={12} />}
        <Col span={isDesktopViewport ? null : 12}>
          <CommonDropdown
            placeholder="User"
            className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
            value={userId}
            onChange={(id, record) => {
              setProjectInstructionFilter({
                ...projectInstructionFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
              setSelectedUserRecord(record);
              setUserId(id);
              if (!id) {
                removeHistoryStateData(
                  navigate,
                  location,
                  'instructionFilter',
                  'userId',
                );
              }
            }}
            allowClear
            showSearch
            optionFilterProp="children"
            query={PROJECT_USER_DROPDOWN}
            variables={{
              filter: {
                projectId: Number(projectId),
                instructionDropdownList: true,
              },
            }}
            responsePath="projectUserDropdownList.data"
            valuePath="id"
            labelPath="name"
            optionKey="user"
            customOptions={selectedUserRecord}
            dropdownMatchSelectWidth={false}
          />
        </Col>
        <Col span={isDesktopViewport ? null : 12}>
          <CommonDropdown
            placeholder="Agency"
            className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
            value={projectAgencyId}
            onChange={(id, record) => {
              setSelectedAgencyRecord(record);
              setProjectAgencyId(id);
              setProjectInstructionFilter({
                ...projectInstructionFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
              if (!id) {
                removeHistoryStateData(
                  navigate,
                  location,
                  'instructionFilter',
                  'projectAgencyId',
                );
              }
            }}
            allowClear
            showSearch
            optionFilterProp="children"
            query={PROJECT_AGENCY_DROPDOWN}
            variables={{
              filter: {
                projectId: Number(projectId),
              },
            }}
            responsePath="projectAgencyDropdownList.data"
            valuePath="id"
            labelPath="agency.name"
            optionKey="user"
            customOptions={selectAgencyRecord}
            dropdownMatchSelectWidth={false}
            placement="bottomRight"
          />
        </Col>
        <Col span={isDesktopViewport ? null : 12}>
          <CommonDropdown
            placeholder="Type"
            className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
            value={type}
            onChange={(value) => {
              setType(value);
              setProjectInstructionFilter({
                ...projectInstructionFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
              if (!value) {
                removeHistoryStateData(
                  navigate,
                  location,
                  'instructionFilter',
                  'type',
                );
              }
            }}
            allowClear
            showSearch
            optionFilterProp="children"
            query={INSTRUCTION_OTHER_TYPE_LIST}
            responsePath="instructionOtherTypeList.data"
            valuePath="type"
            labelPath="type"
            optionKey="type"
            uniqueByLabel="type"
            dropdownMatchSelectWidth={false}
            placement="bottomRight"
          />
        </Col>
        <Col span={isDesktopViewport ? null : 12}>
          <CommonDropdown
            placeholder="Tag"
            mode="multiple"
            maxTagTextLength={5}
            className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
            value={selectedTags}
            onChange={(tag) => {
              if (tag?.length <= 2) {
                setSelectedTags(tag);
                setProjectInstructionFilter({
                  ...projectInstructionFilter,
                  skip: 0,
                });
                setPaginationProp({ ...paginationProp, current: 1 });
              } else if (tag?.length > 2) {
                message.destroy();
                message.error('Only 2 tags are allowed!');
              }
              if (!tag) {
                removeHistoryStateData(
                  navigate,
                  location,
                  'instructionFilter',
                  'projectAgencyId',
                );
              }
            }}
            allowClear
            showSearch
            optionFilterProp="children"
            query={INSTRUCTION_TAG_LIST}
            responsePath="instructionTagList.data"
            valuePath="tag"
            labelPath="tag"
            optionKey="tag"
            uniqueByLabel="tag"
            dropdownMatchSelectWidth={false}
            placement="bottomRight"
            showArrow
          />
        </Col>
        <Col span={isDesktopViewport ? null : 12}>
          <CommonSelect
            allowClear
            name="status"
            placeholder="Status"
            className={`instruction-selector ${isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
            onChange={(status) => {
              setInstructionStatus(status);
              setProjectInstructionFilter({
                ...projectInstructionFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
              if (!status) {
                removeHistoryStateData(
                  navigate,
                  location,
                  'instructionFilter',
                  'status',
                );
              }
            }}
            value={instructionStatus}
            options={[
              ...values(INS_STATUS_KEYS).map((status) => (
                {
                  label: capitalize(status),
                  value: status,
                }
              )),
            ]}
          />
        </Col>
        {isDesktopViewport && (
          <Col>
            <SearchComponent
              className="search-component width-200"
              getData={onSearchChange}
              defaultValue={instructionFilter?.search}
              name="issue"
            />
          </Col>
        )}
      </Row>,
    },
  ]
  return (
    <>
      {showModal && (
        <EditModal
          showModal={showModal}
          setShowModal={setShowModal}
          instructionData={instructionData}
          setInstructionData={setInstructionData}
          uniqueCode={uniqueCode}
          refetchInstructionData={() =>
            fetchProjectInstruction({
              variables: {
                filter: {
                  ...projectInstructionFilter,
                },
              },
            })
          }
        />
      )}
      <div className="project-tab-details">
        <div
          className={`project-tab-details-header  ${isDesktopViewport ? 'd-flex justify-between align-center ' : ''
            }`}
        >
          <h2>Issue Details</h2>
          <CollapsibleFilterWrapper
            searchProps={{
              className: 'search-component',
              getData: onSearchChange,
              defaultValue: instructionFilter?.search,
            }}
            items={items}
          />
        </div >
        {
          isDesktopViewport ? (
            <CommonTable
              className="pointer"
              columns={columns}
              data={instructionListData || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey={(obj) => obj?.id}
              loading={loading}
              onRow={(record) => {
                return {
                  onClick: () => handleRowClick(record),
                };
              }}
            />
          ) : (
            <InfiniteScrollHandler
              scrollFlag={scrollFlag}
              loading={loading}
              refetchData={handleRefetch}
              setScrollFlag={setScrollFlag}
              hasMore={hasMore}
              dataLength={instructionListData?.length}
              skeletonRows={columns?.length - 4}
            >
              {map(instructionListData, (instruction, index) => {
                return (
                  <CommonCard
                    key={instruction?.id}
                    onClick={() => handleRowClick(instruction)}
                  >
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">
                        <div className="d-flex align-center">{index + 1}.</div>
                      </div>
                      <div>
                        <div className="card-header fw-medium">
                          <span className="text-break">{instruction?.name}</span>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15">
                            <span className="fw-medium mr-5">Type:</span>
                            {getTypeName(instruction)}
                          </div>
                          <div className="mb-15">
                            <span className="d-flex align-center fw-medium mr-5 tags-col">
                              Tags
                              {!currentTenant?.tenant?.featureConfig?.issueTag &&
                                getTagsHeader()}
                              &nbsp;:
                            </span>
                            {instruction?.tags?.length > 0
                              ? instruction?.tags?.map((tag) => (
                                <div key={tag}>{`#${tag}`}</div>
                              ))
                              : '-'}
                          </div>
                          <div className="mb-15 d-flex">
                            <span className="fw-medium mr-5">Agency Name:</span>
                            {instruction?.projectAgency?.agency?.name}
                          </div>
                          <div className="mb-15">
                            <span className="fw-medium mr-5">Status:</span>
                            {getStatus(instruction)}
                          </div>
                          <div className="mb-15 d-flex">
                            <span className="fw-medium mr-5">Due Date:</span>
                            {getDueDate(instruction)}
                          </div>
                          <div className="mb-15">
                            <span className="fw-medium mr-5">Updated By:</span>
                            {getUpdatedBy(instruction)}
                          </div>
                          <div className="mb-15">
                            <span className="fw-medium mr-5">Responded By:</span>
                            {getRespondedBy(instruction)}
                          </div>
                          <div>
                            <span className="fw-medium mr-5">Raised At:</span>
                            {instruction?.createdAt
                              ? moment(`${instruction?.createdAt}`)
                                .tz(currentProjectDetails?.timeZone)
                                .format(DEFAULTDATETIMEFORMAT)
                              : '-'}
                          </div>
                        </div>
                      </div>
                      {!(
                        instruction?.status === INS_STATUS_KEYS.CLOSED ||
                        instruction?.status === INS_STATUS_KEYS.NOTICE
                      ) && (
                          <span
                            className="d-flex position-absolute user-action-btn"
                            onClick={(e) => e?.stopPropagation?.()}
                          >
                            <CanPerform
                              action={ALLOWED_ACTION_KEYS.EDIT_INSTRUCTION}
                              type={ALLOWED_ACTION_TYPE.PROJECT}
                            >
                              <Dropdown
                                menu={[
                                  {
                                    key: 'edit',
                                    label: <span onClick={(e) => handleEdit(e, instruction)}>Edit</span>,
                                  },
                                ]}
                                trigger={['click']}
                              >
                                <KebabMenu />
                              </Dropdown>
                            </CanPerform>
                          </span>
                        )}
                    </div>
                  </CommonCard>
                );
              })}
            </InfiniteScrollHandler>
          )}
      </div >
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          projectId={projectId}
          featureKey={REQUEST_FEATURE_UPGRADE_KEYS.ISSUE_TAG}
          isAlreadyRequested={
            currentTenant?.tenant?.featureApprovalRequestConfig
              ?.issueTagRequestSent
          }
        />
      )}
    </>
  );
};

export default Instruction;
