import { DownOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Empty, Form, Row, Tooltip, message } from 'antd';
import clsx from 'clsx';
import {
  debounce,
  every,
  filter,
  findIndex,
  includes,
  keys,
  map,
} from 'lodash';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import { DeleteButton, EditButton } from '../../../assets/svg';
import {
  ACCESS_TYPE,
  ADD_PROJECT_STEPS_KEYS,
  BREAKPOINTS,
  DROPDOWN_LIMIT,
  GA_EVENT,
  GA_LABEL,
  PROJECT_USER_ROLES,
  ROUTES,
  SCROLL_CONST,
  USER_TAB_ROLES,
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import useRouter from '../../../common/useRouter';
import { titleCase } from '../../../common/utils';
import CommonCard from '../../../components/CommonCard';
import CommonSelect from '../../../components/CommonSelect';
import CommonTable from '../../../components/CommonTable';
import HasAccess from '../../../components/HasAccess';
import { ONBOARD_PROJECT_USERS } from '../graphql/Mutation';
import { GET_ALL_USERS, GET_ONBOARDED_PROJECT_USERS } from '../graphql/Queries';
import AddProjectUserModal from './AddProjectUserModal';

let searchDebounce = null;
const AddProjectUsers = () => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [allUsers, setAllUsers] = useState([]);
  const { navigate, params: { projectId } } = useRouter()
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const hasInstructionAccess = !!HasAccess({ type: ACCESS_TYPE.INSTRUCTION });
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showSelectRole, setShowSelectRole] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const [fetchAllUsers, { loading: allUsersLoading }] = useLazyQuery(
    GET_ALL_USERS,
    {
      fetchPolicy: 'network-only',
      variables: {
        filter: { skip: 0, limit: DROPDOWN_LIMIT, projectId },
      },
      onCompleted: (res) => {
        const data = res?.projectUserDropdownList?.data;
        if (currentPage === 0) {
          setAllUsers(data);
        } else {
          setAllUsers((oldData) => [...oldData, ...data]);
        }
        setCurrentPage((page) => page + 1);
        setHasMore(data?.length >= DROPDOWN_LIMIT);
      },
    },
  );
  const { data: existingUsers, loading: projectUsersLoading } = useQuery(
    GET_ONBOARDED_PROJECT_USERS,
    {
      fetchPolicy: 'network-only',
      variables: { filter: { projectId } },
      onCompleted: (res) => {
        setTableData([
          ...tableData,
          ...map(res?.onboardProjectUserList?.data, (data) => ({
            ...data?.user,
            newRoles: [data?.roles],
          })),
        ]);
      },
    },
  );

  const [onboardProjectUsers, { loading: mutationLoading }] = useMutation(
    ONBOARD_PROJECT_USERS,
    {
      onError() { },
      onCompleted: () => {
        Event(GA_EVENT.ONBOARD_PROJECT_USER, {
          label: GA_LABEL.ONBOARD_PROJECT_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        navigate(
          `${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.AGENCIES}`,
        );
      },
    },
  );
  useEffect(() => {
    fetchAllUsers({
      variables: {
        filter: {
          skip: currentPage * DROPDOWN_LIMIT,
          limit: DROPDOWN_LIMIT,

          projectId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const EditableContext = createContext(null);
  const EditableRow = ({ index, ...props }) => {
    const [editableForm] = Form.useForm();
    return (
      <Form form={editableForm} component={false}>
        <EditableContext.Provider value={editableForm}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  const EditableCell = ({
    editable,
    children,
    dataIndex,
    record,
    title,
    ...restProps
  }) => {
    const inputRef = useRef(null);
    const editableForm = useContext(EditableContext);

    useEffect(() => {
      if (isOpen) {
        inputRef?.current?.focus();
        editableForm.setFieldsValue({
          [dataIndex]: record?.newRoles?.[0],
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const save = async () => {
      try {
        const values = await editableForm.validateFields();
        if (values?.roles) {
          const index = findIndex(tableData, { id: updateData?.id });
          tableData[index].newRoles = [values?.roles];
          setUpdateData();
          setIsOpen(false);
        }
      } catch (errInfo) {
        // eslint-disable-next-line no-console
        console.log('Save failed:', errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode =
        updateData?.id === record?.id ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <CommonSelect
              inputRef={inputRef}
              open={isOpen && updateData?.id === record?.id}
              onBlur={save}
              onSelect={save}
              options={[
                ...map(keys(PROJECT_USER_ROLES), (role, roleIndex) => {
                  return {
                    key: `role-${roleIndex}`,
                    value: role,
                    label: titleCase(PROJECT_USER_ROLES[role]),
                  }
                }),
              ]}
            />
          </Form.Item>
        ) : (
          <div
            className={clsx(
              'editable-cell-value-wrap',
              includes(
                [USER_TAB_ROLES.EXTERNAL_AUDITOR, USER_TAB_ROLES.SYSTEM_ADMIN],
                record?.roles?.[0],
              )
                ? 'cursor-not-allowed'
                : 'pointer',
            )}
            onClick={() => {
              if (
                !includes(
                  [
                    USER_TAB_ROLES.EXTERNAL_AUDITOR,
                    USER_TAB_ROLES.SYSTEM_ADMIN,
                  ],
                  record?.roles?.[0],
                )
              ) {
                setIsOpen(true);
                setUpdateData(record);
              }
            }}
          >
            {children}
          </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const formSubmit = () => {
    if (!tableData?.length > 0) {
      message.destroy();
      return message.error('Add at least one user!');
    }
    if (!every(tableData, 'newRoles')) {
      message.destroy();
      return message.error('Missing user role!');
    }
    const access = [];
    if (hasInstructionAccess) {
      access.push(ACCESS_TYPE.INSTRUCTION);
    }
    const data = map(tableData, ({ id, newRoles }) => {
      return {
        roles: newRoles?.[0],
        userId: Number(id),
        projectId,
        webAccess: true,
        permissions: [],
        projectEqcTypeIds: [],
        access,
      };
    });
    onboardProjectUsers({
      variables: { data },
    });
  };

  useEffect(() => {
    if (existingUsers?.onboardProjectUserList?.data?.length) {
      const tempArr = [];
      const disabledRole = [];
      const data = map(
        existingUsers?.onboardProjectUserList?.data,
        ({ user: { id, roles: userRole }, roles }) => {
          tempArr.push(id);
          if (
            userRole.includes(USER_TAB_ROLES.SYSTEM_ADMIN) ||
            roles === USER_TAB_ROLES.EXTERNAL_AUDITOR
          ) {
            disabledRole.push(true);
          } else {
            disabledRole.push(false);
          }
          return { projectUserId: id, roles };
        },
      );
      form.setFieldsValue({
        projectUsers: data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingUsers]);

  const searchQuery = (search) => {
    setSearchText(search);
    setCurrentPage(0);
    fetchAllUsers({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          skip: 0,
          limit: DROPDOWN_LIMIT,
          search,
          projectId,
        },
      },
    });
  };

  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && hasMore && !allUsersLoading) {
      fetchAllUsers({
        variables: {
          filter: {
            skip: currentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            search: searchText,
            projectId,
          },
        },
      });
    }
  };
  const getRole = (record) => {
    return isDesktopViewport ? (
      <div className="d-flex justify-between align-center readonly-select">
        <div>
          {titleCase(
            filter(tableData, (user) => user?.id === record?.id)?.[0]
              .newRoles?.[0],
          ) || '-'}
        </div>
        <DownOutlined />
      </div>
    ) : (
      titleCase(
        filter(tableData, (user) => user?.id === record?.id)?.[0].newRoles?.[0],
      ) || '-'
    );
  };
  const getActionButtons = (record) => {
    const handleEditModal = (data) => {
      setShowModal(true);
      setShowSelectRole(true);
      setUpdateData(data);
    };
    const deleteUser = (data) => {
      setTableData(filter(tableData, (user) => user?.id !== data?.id));
    };
    return (
      <div className="d-flex justify-end">
        {!isDesktopViewport && (
          <Tooltip title="Edit">
            <Button
              shape="round"
              icon={<EditButton />}
              disabled={includes(
                [USER_TAB_ROLES.EXTERNAL_AUDITOR, USER_TAB_ROLES.SYSTEM_ADMIN],
                record?.roles?.[0],
              )}
              className={clsx(
                'edit-button pointer b-0 no-background-and-border',
                includes(
                  [
                    USER_TAB_ROLES.EXTERNAL_AUDITOR,
                    USER_TAB_ROLES.SYSTEM_ADMIN,
                  ],
                  record?.roles?.[0],
                ) && 'disabled-button ',
              )}
              onClick={() => handleEditModal(record)}
            />
          </Tooltip>
        )}
        <Tooltip title="Delete">
          <Button
            className="delete-button pointer b-0"
            shape="round"
            icon={<DeleteButton />}
            onClick={() => deleteUser(record)}
          />
        </Tooltip>
      </div>
    );
  };
  const defaultColumns = [
    {
      title: (
        <div>
          <span className="required-mark">*</span>
          <span>Name</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <div>
          <span className="required-mark">*</span>
          <span>Role</span>
        </div>
      ),
      dataIndex: 'roles',
      key: 'roles',
      editable: true,
      render: (role, record) => {
        return <div>{getRole(record)}</div>;
      },
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => getActionButtons(record),
    },
  ];

  const columns = defaultColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col?.editable,
        dataIndex: col?.dataIndex,
        title: col?.title,
      }),
    };
  });

  const handleAddModalSubmit = (values) => {
    if (values?.user) {
      setTableData([
        ...tableData,
        ...map(values?.user, (user) => ({
          ...JSON.parse(user),
          newRoles:
            JSON.parse(user)?.roles?.[0] !== USER_TAB_ROLES?.USER
              ? JSON.parse(user)?.roles
              : [USER_TAB_ROLES?.INSPECTOR],
        })),
      ]);
    }
    if (values?.role) {
      const index = findIndex(tableData, { id: updateData?.id });
      tableData[index].newRoles = [values?.role];
      setUpdateData();
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (tableData?.length > 0) {
      map(tableData, (data, index) => {
        if (data?.roles?.[0] === USER_TAB_ROLES?.SYSTEM_ADMIN) {
          tableData[index].newRoles = [USER_TAB_ROLES?.PROJECT_ADMIN];
        } else if (data?.roles?.[0] === USER_TAB_ROLES?.EXTERNAL_AUDITOR) {
          tableData[index].newRoles = [USER_TAB_ROLES?.AUDITOR];
        }
      });
    }
  }, [tableData]);

  const handleSearch = (value) => {
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(value);
  };
  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };
  return (
    <>
      <div className="header d-flex align-center justify-between mb-20">
        <h2 className="m-0">User</h2>
        <div className="eqc-stage-header-buttons d-flex">
          <Button
            shape="round"
            type="primary"
            id="add-btn"
            className="add-button"
            onClick={() => setShowModal(true)}
          >
            Add
          </Button>
        </div>
      </div>
      {isDesktopViewport ? (
        <div className="mb-20 editable-table">
          <CommonTable
            components={components}
            data={tableData}
            columns={columns}
            loading={projectUsersLoading}
            rowKey="id"
          />
        </div>
      ) : (
        <div className=" position-relative">
          {tableData.length ? (
            <div className="mb-15 width-percent-100 infinite-data-container">
              {map(tableData, (user, index) => {
                return (
                  <CommonCard key={user?.id}>
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">{index + 1}.</div>
                      <div>
                        <div className="card-header fw-medium">
                          <span>{user?.name}</span>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15 d-flex align-center">
                            <span className="fw-medium mr-10">Role:</span>
                            {getRole(user)}
                          </div>
                        </div>
                      </div>
                      <span className="d-flex position-absolute user-action-btn">
                        {getActionButtons(user, index)}
                      </span>
                    </div>
                  </CommonCard>
                );
              })}
            </div>
          ) : (
            <Empty description="No user added yet!" className="mb-20" />
          )}
        </div>
      )}
      {showModal && (
        <AddProjectUserModal
          showModal={showModal}
          setShowModal={setShowModal}
          data={allUsers}
          handleModalSubmit={handleAddModalSubmit}
          selectedUsers={tableData}
          onPopupScroll={handleScroll}
          onSearch={handleSearch}
          onClear={handleClear}
          showSelectRole={showSelectRole}
          setShowSelectRole={setShowSelectRole}
          setUpdateData={setUpdateData}
        />
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={formSubmit}
        initialValues={{ projectUsers: [{ user: undefined, role: undefined }] }}
      >
        <Row justify="end">
          <Col className="form-buttons">
            <Button
              shape="round"
              onClick={() => {
                Event(GA_EVENT.CLICK_PREVIOUS_BUTTON, {
                  label: `While onboard project users`,
                  // eslint-disable-next-line no-undef
                  pathname: window?.location?.href,
                  project_id: projectId,
                  user_id: currentUser?.id,
                  user_name: currentUser?.name,
                  tenant_id: currentUser?.tenantUser?.tenant?.id,
                  tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
                });
                navigate(
                  `${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.DETAILS}`,
                );
              }}
              className="cancel-button"
            >
              Previous
            </Button>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              loading={mutationLoading}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddProjectUsers;
