import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Switch } from 'antd';
import { keys, map } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { GA_EVENT, GA_LABEL, UOMS } from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import CommonDropdown from '../../components/CommonDropdown';
import CommonSelect from '../../components/CommonSelect';
import { CREATE_EQC_TYPE, UPDATE_EQC_TYPE } from './graphql/Mutation';
import { TEMPLATE_LIST } from './graphql/Queries';

const { required, characterWithoutWhiteSpace } = formValidatorRules;
const AddEditEqcModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    eqcData,
    setEqcData,
    refetchUserDataWithInitialValues,
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [createEqcMutate, { loading: createUserLoading }] = useMutation(
    CREATE_EQC_TYPE,
    {
      onError() { },
      onCompleted() {
        Event(GA_EVENT.ADD_GLOBAL_CHECKLIST, {
          label: GA_LABEL.ADD_GLOBAL_CHECKLIST,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setEqcData();
        form.resetFields();
        setShowModal(false);
        refetchUserDataWithInitialValues();
      },
    },
  );

  const [updateEqcMutate, { loading: updateUserLoading }] = useMutation(
    UPDATE_EQC_TYPE,
    {
      onError() { },
      onCompleted() {
        Event(GA_EVENT.EDIT_GLOBAL_CHECKLIST, {
          label: GA_LABEL.EDIT_GLOBAL_CHECKLIST,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          checklist_id: eqcData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setEqcData();
        form.resetFields();
        setShowModal(false);
        refetchUserDataWithInitialValues();
      },
    },
  );

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setEqcData();
  };

  const onUserSubmitFinish = async (formValues) => {
    const newFormValues = {
      ...formValues,
    };

    const variables = isUpdate
      ? {
        data: { ...newFormValues },
        id: eqcData.id,
      }
      : { ...newFormValues, selectedAll: isSelectedAll };
    if (isSelectedAll) {
      variables.eqcTypeId = [];
    }
    try {
      if (isUpdate) {
        await updateEqcMutate({
          variables: { ...variables },
        });
        return;
      }
      await createEqcMutate({
        variables: {
          data: {
            ...variables,
            useEqcTypeTemplate: !!checked,
          },
        },
      });
    } catch (error) {
      return error;
    }
  };

  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };

  const handleTemplate = () => {
    setChecked(!checked);
  };
  const handleDeselect = () => {
    setIsSelectedAll(false);
    form.setFieldsValue({ eqcTypeId: [] });
  };
  return (
    <Modal
      maskClosable={false}
      open={showModal}
      centered
      confirmLoading={createUserLoading || updateUserLoading}
      onOk={handleAdd}
      className="dialog"
      okText={isUpdate ? 'Save' : 'Add'}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <h2>{isUpdate ? 'Edit Checklist' : 'Add Checklist'}</h2>
      <Form
        form={form}
        initialValues={eqcData}
        layout="vertical"
        onFinish={onUserSubmitFinish}
      >
        {!isUpdate && (
          <Form.Item name="useEqcTypeTemplate" label="digiQC Template">
            <Switch onChange={handleTemplate} />
          </Form.Item>
        )}
        {!checked ? (
          <>
            <Form.Item
              rules={[
                required,
                characterWithoutWhiteSpace,
                {
                  max: 250,
                  message: 'Name cannot be more than 250 characters',
                },
              ]}
              name="name"
              label="Name"
            >
              <Input allowClear placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              rules={[{ required, message: 'Required' }]}
              name="uom"
              label="UOM"
            >
              <CommonSelect
                className="mr-3"
                placeholder="Select UOM"
                allowClear
                showSearch
                optionFilterProp="children"
                options={[
                  ...map(keys(UOMS), (uom) => {
                    return {
                      key: uom,
                      value: uom,
                      label: UOMS[uom],
                    }
                  }),
                ]}
              />
            </Form.Item>
            <Form.Item
              name="iso"
              label="Reference Number"
              rules={[
                characterWithoutWhiteSpace,
                {
                  max: 250,
                  message: 'Content cannot be more than 250 characters',
                },
              ]}
            >
              <Input allowClear placeholder="Reference Number" />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            rules={[required]}
            name={isUpdate ? 'template' : 'eqcTypeId'}
            label={
              <div className="d-flex justify-between width-percent-100">
                <div>Select Template</div>
                {isSelectedAll && (
                  <div
                    className="text-primary pointer"
                    role="button"
                    tabIndex="0"
                    onClick={handleDeselect}
                  >
                    Deselect All
                  </div>
                )}
              </div>
            }
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectedAll(true);
                return ['all'];
              }
              return value;
            }}
          >
            <CommonDropdown
              placeholder="Select Template"
              allowClear
              mode="multiple"
              showSearch
              optionFilterProp="children"
              query={TEMPLATE_LIST}
              fetchPolicy="network-only"
              responsePath="masterEqcTypeList.data"
              valuePath="id"
              labelPath="name"
              optionKey="eqc-type-template"
              disabled={isSelectedAll}
              isSelectedAll={isSelectedAll}
              hasSelectAll
            />
          </Form.Item>
        )}

        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditEqcModal;
