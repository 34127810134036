import { useMutation } from '@apollo/client';
import { Button, Col, DatePicker, Form, Modal, Row } from 'antd';
import { get, orderBy, range } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  DATEFORMATWITHDASH,
  DEFAULTDATEFORMAT,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../common/utils';
import { MARK_QC_PASS } from '../../../../graphql/Mutation';

const MarkQCPassModal = (props) => {
  const {
    showModal,
    setShowModal,
    eqcData,
    setEqcData,
    refetchEqcData,
  } = props;
  const [isLastStage, setIsLastStage] = useState(false);
  const { params: { projectId } } = useRouter();
  const [form] = Form.useForm();
  const { required } = formValidatorRules;

  const handleCancel = () => {
    setShowModal(false);
    setEqcData();
    form.resetFields();
  };
  useEffect(() => {
    if (eqcData) {
      const orderedEQCStages = orderBy(
        get(eqcData, 'eqcStages'),
        ['id'],
        ['desc'],
      );
      setIsLastStage(
        Number(get(eqcData, 'stageHistories[0].eqcStageId')) ===
        Number(get(orderedEQCStages, '[0].id')),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcData]);

  const [markQCPass, { loading }] = useMutation(MARK_QC_PASS, {
    onError() { },
    onCompleted() {
      setEqcData();
      form.resetFields();
      setShowModal(false);
      refetchEqcData();
    },
  });

  const onFormSubmit = async ({
    inspectorReminderDate,
    inspectorReminderTime,
    projectAgencyId,
  }) => {
    const combineDate = inspectorReminderDate.set({
      hour: moment(inspectorReminderTime, 'HH:mm')?.hours(),
      minute: moment(inspectorReminderTime, 'HH:mm')?.minutes(),
      second: moment(inspectorReminderTime, 'HH:mm')?.seconds(),
      millisecond: 0,
    });
    markQCPass({
      variables: {
        data: {
          eqcId: Number(eqcData?.id),
          eqcStageId: Number(get(eqcData, 'eqcStageHistories[0].eqcStageId')),
          projectId: Number(projectId),
          inspectorReminderDate: moment(combineDate)?.utc()?.format(),
          projectAgencyId,
        },
      },
    });
  };

  return (
    <Modal
      maskClosable={false}
      centered
      form={form}
      open={showModal}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <h2 className="mb-15">Mark QC Pass</h2>
      <Form layout="vertical" form={form} onFinish={onFormSubmit}>
        {!isLastStage && (
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="inspectorReminderDate"
                label="Reminder Date"
                rules={[required]}
              >
                <DatePicker
                  format={DATEFORMATWITHDASH}
                  className="width-percent-100"
                  disabledDate={(current) =>
                    current && current < moment().startOf('day')
                  }
                  onChange={() =>
                    form.setFieldsValue({ inspectorReminderTime: '' })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="inspectorReminderTime"
                label="Reminder Time"
                dependencies={['inspectorReminderDate']}
                rules={[required]}
              >
                <DatePicker
                  picker="time"
                  className="width-percent-100"
                  showSecond={false}
                  inputReadOnly
                  disabledTime={() => ({
                    disabledHours: () =>
                      range(0, 24).splice(
                        0,
                        form.getFieldValue('inspectorReminderDate') &&
                          moment().format(DEFAULTDATEFORMAT) ===
                          moment(
                            form.getFieldValue('inspectorReminderDate'),
                          )?.format(DEFAULTDATEFORMAT)
                          ? moment()?.hour()
                          : 0,
                      ),
                    disabledMinutes: (value) =>
                      range(0, 60).splice(
                        0,
                        form.getFieldValue('inspectorReminderDate') &&
                          moment()?.format(DEFAULTDATEFORMAT) ===
                          moment(
                            form.getFieldValue('inspectorReminderDate'),
                          ).format(DEFAULTDATEFORMAT) &&
                          value === moment()?.hour()
                          ? moment()?.minute()
                          : 0,
                      ),
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default MarkQCPassModal;
