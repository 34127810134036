import { DownOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Empty, Form, Row, Tooltip, message } from 'antd';
import clsx from 'clsx';
import {
  debounce,
  filter,
  findIndex,
  includes,
  isEmpty,
  map,
  nth,
  pull,
  remove,
  slice,
  some,
} from 'lodash';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import { DeleteButton, EditButton } from '../../../assets/svg';
import {
  ADD_PROJECT_STEPS_KEYS,
  BREAKPOINTS,
  DROPDOWN_LIMIT,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  SCROLL_CONST,
  TAB_KEYS,
  WARNINGS,
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import useRouter from '../../../common/useRouter';
import CommonCard from '../../../components/CommonCard';
import CommonSelect from '../../../components/CommonSelect';
import CommonTable from '../../../components/CommonTable';
import { ONBOARD_PROJECT_EQC_TYPES } from '../graphql/Mutation';
import {
  GET_ALL_EQC_TYPE,
  GET_PROJECT_AGENCY_FOR_EQC_TYPES,
  GET_PROJECT_USERS_FOR_EQC_TYPES,
} from '../graphql/Queries';
import AddProjectEqcTypeModal from './AddProjectEqcTypeModal';
import EditProjectEqcTypeModal from './EditProjectEqcTypeModal';

let searchDebounce = null;

const AddProjectEqcType = () => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [selectedEqcTypes, setSelectedEqcTypes] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState({});
  const { navigate, params: { projectId } } = useRouter()
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [allEqcTypes, setAllEqcTypes] = useState([]);
  const [isUserRequired, setIsUserRequired] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState();
  const [selectedAgencies, setSelectedAgencies] = useState();
  const [isOpenForUser, setIsOpenForUser] = useState(false);
  const [isOpenForAgency, setIsOpenForAgency] = useState(false);
  const { data: userList, loading: userListLoading } = useQuery(
    GET_PROJECT_USERS_FOR_EQC_TYPES,
    {
      fetchPolicy: 'network-only',
      variables: { filter: { projectId } },
    },
  );

  const { data: agencyList, loading: agencyListLoading } = useQuery(
    GET_PROJECT_AGENCY_FOR_EQC_TYPES,
    {
      fetchPolicy: 'network-only',
      variables: { filter: { projectId } },
    },
  );
  const [fetchAllEqcTypes, { loading }] = useLazyQuery(GET_ALL_EQC_TYPE, {
    fetchPolicy: 'network-only',
    variables: { filter: { projectId } },
    onCompleted: (res) => {
      const data = res?.projectEqcTypeDropdownList?.data;
      if (currentPage === 0) {
        setAllEqcTypes(data);
      } else {
        setAllEqcTypes((oldData) => [...oldData, ...data]);
      }
      setCurrentPage((page) => page + 1);
      setHasMore(data?.length >= DROPDOWN_LIMIT);
    },
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  useEffect(() => {
    fetchAllEqcTypes({
      variables: {
        filter: {
          skip: currentPage * DROPDOWN_LIMIT,
          limit: DROPDOWN_LIMIT,
          projectId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const deleteEqcType = (recordId) => {
    const newIds = [...selectedEqcTypes];
    const newData = [...tableData];
    pull(newIds, recordId);
    remove(newData, ({ id }) => id === recordId);
    setSelectedEqcTypes(newIds);
    setTableData(newData);
  };
  const handleEditModal = (record, index) => {
    setEditData({ ...record, index });
    setShowEditModal(true);
  };
  const [onboardProjectEqcTypes, { loading: mutationLoading }] = useMutation(
    ONBOARD_PROJECT_EQC_TYPES,
    {
      onError() { },
      onCompleted: (res) => {
        Event(GA_EVENT.ONBOARD_PROJECT_CHECKLIST, {
          label: GA_LABEL.ONBOARD_PROJECT_CHECKLIST,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        if (res?.onboardProjectEqcTypes) {
          navigate(`${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.EQC}`);
        }
      },
    },
  );

  const EditableContext = createContext(null);
  const EditableRow = ({ index, ...props }) => {
    const [editableForm] = Form.useForm();
    return (
      <Form form={editableForm} component={false}>
        <EditableContext.Provider value={editableForm}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    editable,
    children,
    dataIndex,
    record,
    title,
    ...restProps
  }) => {
    const editableForm = useContext(EditableContext);
    const inputRef = useRef(null);

    useEffect(() => {
      if (isOpenForUser || isOpenForAgency) {
        inputRef?.current?.focus();
        editableForm.setFieldsValue({
          [dataIndex]: record?.[dataIndex],
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenForUser, isOpenForAgency]);

    const save = async () => {
      try {
        const values = await editableForm.validateFields();
        if (dataIndex === 'user') {
          const index = findIndex(tableData, { id: selectedUsers?.id });
          if (values?.user?.length > 0) {
            tableData[index].user = values?.user;
          } else {
            delete tableData[index].user;
          }
          setSelectedUsers();
          setIsOpenForUser(false);
        } else {
          const index = findIndex(tableData, { id: selectedAgencies?.id });
          if (values?.agency?.length > 0) {
            tableData[index].agency = values?.agency;
          } else {
            delete tableData[index].agency;
          }
          setSelectedAgencies();
          setIsOpenForAgency(false);
        }
      } catch (errInfo) {
        // eslint-disable-next-line no-console
        console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;
    if (editable) {
      childNode =
        (dataIndex === 'user' && selectedUsers?.id === record?.id) ||
          (dataIndex === 'agency' && selectedAgencies?.id === record?.id) ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            {dataIndex === 'user' && selectedUsers?.id === record?.id ? (
              <CommonSelect
                placeholder="Select Users"
                allowClear
                loading={userListLoading}
                mode="multiple"
                onBlur={save}
                inputRef={inputRef}
                open={isOpenForUser && selectedUsers?.id === record?.id}
                onClear={save}
                optionFilterProp="children"
                getPopupContainer={() =>
                  // eslint-disable-next-line no-undef
                  document.querySelector('.editable-table')
                }
                options={[
                  ...map(
                    userList?.projectEqcTypeUserDropdownList?.data,
                    ({ id, user }) => {
                      return {
                        key: `contact-${id}`,
                        value: id,
                        label: user?.name,
                      }
                    }),
                ]}
              />
            ) : (
              <CommonSelect
                placeholder="Select Agencies"
                allowClear
                inputRef={inputRef}
                loading={agencyListLoading}
                mode="multiple"
                open={isOpenForAgency && selectedAgencies?.id === record?.id}
                onBlur={save}
                onClear={save}
                optionFilterProp="children"
                getPopupContainer={() =>
                  // eslint-disable-next-line no-undef
                  document.querySelector('.editable-table')
                }
                options={[
                  ...map(
                    agencyList?.projectEqcTypeAgencyDropdownList?.data,
                    ({ id, agency }) => {
                      return {
                        key: `contact-${id}`,
                        value: id,
                        label: agency?.name,
                      }
                    }),
                ]}
              />
            )}
          </Form.Item>
        ) : (
          <div
            className={clsx('editable-cell-value-wrap')}
            onClick={() => {
              if (dataIndex === 'user') {
                setSelectedUsers(record);
                setIsOpenForUser(true);
              } else {
                setSelectedAgencies(record);
                setIsOpenForAgency(true);
              }
            }}
          >
            {children}
          </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const getActionButtons = (record, index) => {
    return (
      <div className="d-flex justify-end">
        {!isDesktopViewport && (
          <Tooltip title="Edit">
            <Button
              shape="round"
              icon={<EditButton />}
              className="edit-button pointer b-0"
              onClick={() => handleEditModal(record, index)}
            />
          </Tooltip>
        )}
        <Tooltip title="Delete">
          <Button
            className="delete-button pointer b-0"
            shape="round"
            icon={<DeleteButton />}
            onClick={() => deleteEqcType(record?.id)}
          />
        </Tooltip>
      </div>
    );
  };
  const getUsers = (record) => {
    const fetchRecord = filter(
      userList?.projectEqcTypeUserDropdownList?.data,
      (data) => includes(record, data?.id),
    );
    const fetchName = map(fetchRecord, 'user');
    const selectedName = nth(fetchName, 0);
    let children = 'Select Users';
    if (fetchName?.length === 1) {
      children = (
        <Tooltip title={selectedName?.name} placement="right">
          <span className="ellipsis-text eqc-type-contact-name">
            {selectedName?.name}
          </span>
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      children = (
        <Tooltip
          title={map(slice(fetchName, 1, fetchName?.length), 'name').join(', ')}
          placement="right"
        >
          <div className="d-flex">
            <div className="ellipsis-text eqc-type-contact-name-with-count mr-5">
              {selectedName?.name}
            </div>
            <div className="contact-count">{`and +${fetchName?.length - 1
              }`}</div>
          </div>
        </Tooltip>
      );
    }
    return isDesktopViewport ? (
      <div
        className={clsx(
          'd-flex justify-between align-center readonly-select',
          !fetchName?.length > 0 && 'placeholder-text readonly-select-with-glow',
        )}
      >
        {children} <DownOutlined />
      </div>
    ) : (
      <>{children}</>
    );
  };

  const getAgencies = (record) => {
    const fetchRecord = filter(
      agencyList?.projectEqcTypeAgencyDropdownList?.data,
      (data) => includes(record, data?.id),
    );
    const fetchName = map(fetchRecord, 'agency');
    const selectedName = nth(fetchName, 0);
    let children = 'Select Agencies';
    if (fetchName?.length === 1) {
      children = (
        <Tooltip title={selectedName?.name} placement="right">
          <span className="ellipsis-text eqc-type-contact-name">
            {selectedName?.name}
          </span>
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      children = (
        <Tooltip
          title={map(slice(fetchName, 1, fetchName?.length), 'name').join(', ')}
          placement="right"
        >
          <div className="d-flex">
            <div className="ellipsis-text eqc-type-contact-name-with-count mr-5">
              {selectedName?.name}
            </div>
            <div className="contact-count">{`and +${fetchName?.length - 1
              }`}</div>
          </div>
        </Tooltip>
      );
    }
    return isDesktopViewport ? (
      <div
        className={clsx(
          'd-flex justify-between align-center readonly-select',
          !fetchName?.length > 0 && 'placeholder-text readonly-select-with-glow',
        )}
      >
        {children} <DownOutlined />
      </div>
    ) : (
      <>{children}</>
    );
  };
  const defaultColumns = [
    {
      title: (
        <div>
          <span className="required-mark">*</span>
          <span>Checklist</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'USER',
      dataIndex: 'user',
      key: 'user',
      editable: true,
      width: '30%',
      render: (user) => getUsers(user),
    },
    {
      title: (
        <div>
          <span className="required-mark">*</span>
          <span>AGENCY</span>
        </div>
      ),
      key: 'agency',
      dataIndex: 'agency',
      editable: true,
      width: '30%',
      render: (agency) => getAgencies(agency),
    },
    {
      key: 'action',
      render: (text, record, index) => getActionButtons(record, index),
    },
  ];

  const columns = defaultColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });
  const handleAddModalSubmit = ({ type }) => {
    const newData = [...tableData];
    const newIds = [...selectedEqcTypes];
    map(type, (eqcType) => {
      const parsedData = JSON.parse(eqcType);
      newData.push(parsedData);
      newIds.push(parsedData?.id);
    });
    setTableData(newData);
    setSelectedEqcTypes(newIds);
    setShowAddModal(false);
  };
  const handleEditModalSubmit = (values) => {
    const { user, agency, index } = values;
    const newData = [...tableData];
    const temp = newData[index];
    newData[index] = {
      ...temp,
      user,
      agency,
    };
    setTableData(newData);
    setShowEditModal(false);
  };

  const submitProject = () => {
    if (!tableData.length) {
      message.destroy();
      return message.error('Please add Checklists before proceeding!');
    }
    const data = [];
    const validated = !some(tableData, ({ id, user, agency }) => {
      if (isEmpty(agency)) {
        message.destroy();
        return message.error('Missing agencies!');
      }
      data.push({
        projectId,
        eqcTypeId: Number(id),
        projectUserIds: user,
        projectAgencyIds: agency,
      });
    });

    if (validated) {
      onboardProjectEqcTypes({
        variables: { data },
      });
    }
  };
  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && hasMore && !loading) {
      fetchAllEqcTypes({
        variables: {
          filter: {
            skip: currentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            projectId,
            search: searchText,
          },
        },
      });
    }
  };
  const searchQuery = (search) => {
    setSearchText(search);
    setCurrentPage(0);
    fetchAllEqcTypes({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          skip: 0,
          limit: DROPDOWN_LIMIT,
          search,
          projectId,
        },
      },
    });
  };
  const handleSearch = (value) => {
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(value);
  };
  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };
  return (
    <>
      <div className="header d-flex align-center justify-between mb-20">
        <h2 className="m-0">Checklist</h2>
        <div className="eqc-stage-header-buttons d-flex">
          <Button
            shape="round"
            type="primary"
            id="add-btn"
            className="add-button"
            onClick={() => setShowAddModal(true)}
          >
            Add
          </Button>
        </div>
      </div>
      {isDesktopViewport ? (
        <div className="mb-20 editable-table">
          <CommonTable
            components={components}
            data={tableData}
            columns={columns}
            rowKey="id"
            className="add-project-eqc-type-table"
          />
          <div className="text-warning mt-10 mb-15">
            {WARNINGS.PROJECT_ADMIN_AUTO_ASSIGNED}
          </div>
        </div>
      ) : (
        <div className=" position-relative">
          {tableData.length ? (
            <div className="mb-15 width-percent-100 infinite-data-container">
              {map(tableData, (user, index) => {
                return (
                  <CommonCard key={user?.id}>
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">{index + 1}.</div>
                      <div>
                        <div className="card-header fw-medium">
                          <span>{user?.name}</span>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15 d-flex align-center">
                            <span className="fw-medium mr-10">Users:</span>
                            {getUsers(user?.user)}
                          </div>
                          <div className=" d-flex align-center">
                            <span className="fw-medium mr-10">Agencies:</span>
                            {getAgencies(user?.agency)}
                          </div>
                        </div>
                      </div>
                      <span className="d-flex position-absolute user-action-btn">
                        {getActionButtons(user, index)}
                      </span>
                    </div>
                  </CommonCard>
                );
              })}
            </div>
          ) : (
            <Empty description="No Checklist added yet!" className="mb-20" />
          )}
        </div>
      )}
      <Row justify="end">
        <Col className="form-buttons">
          <Button
            shape="round"
            onClick={() => {
              Event(GA_EVENT.CLICK_PREVIOUS_BUTTON, {
                label: `While onboard project Checklists`,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
                project_id: projectId,
                user_id: currentUser?.id,
                user_name: currentUser?.name,
                tenant_id: currentUser?.tenantUser?.tenant?.id,
                tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
              });
              navigate(
                `${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.AGENCIES}`,
              );
            }}
            className="cancel-button"
          >
            Previous
          </Button>
          <Button
            shape="round"
            type="primary"
            onClick={submitProject}
            loading={mutationLoading}
          >
            Add Project
          </Button>
        </Col>
      </Row>
      <AddProjectEqcTypeModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        data={allEqcTypes}
        handleModalSubmit={handleAddModalSubmit}
        selectedEqcTypes={selectedEqcTypes}
        onPopupScroll={handleScroll}
        onSearch={handleSearch}
        onClear={handleClear}
      />
      <EditProjectEqcTypeModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        data={editData}
        projectId={projectId}
        handleModalSubmit={handleEditModalSubmit}
        isUserRequired={isUserRequired}
        setIsUserRequired={setIsUserRequired}
      />
    </>
  );
};

export default AddProjectEqcType;
