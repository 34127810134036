import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Input,
  Popconfirm,
  Popover,
  Row,
  Spin,
  Tag,
  message,
} from 'antd';
import clsx from 'clsx';
import { isNaN, map } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import {
  ArrowLeft,
  ArrowRight,
  Import,
  InfoIcon,
} from '../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DAY_DATE_MONTH_YEAR_FORMAT,
  DEFAULTDATEFORMAT,
  REGEX,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import CanPerform from '../../../../../../components/CanPerform';
import CommonCard from '../../../../../../components/CommonCard';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import { UPDATE_PROJECT_WEEKLY_TARGET } from '../../../../graphql/Mutation';
import { TARGET_ACTUAL_METRIC_LIST } from '../../../../graphql/Queries';

const PlanAndTrack = () => {
  const isTabletViewport = useMedia(`(min-width: ${BREAKPOINTS.tablet}px)`);
  const [targetActualMetricsData, setTargetActualMetricsData] = useState([]);
  const [currentPageCount, setCurrentPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [valueInput, setValueInput] = useState(null);
  const {
    params: { projectId },
  } = useRouter();
  const { getToken, getCurrentTenant, getTenantUser, getCurrentProjectDetail } =
    useContext(AppContext);
  const currentProjectDetails = getCurrentProjectDetail();
  const token = getToken();
  const tenantId = getCurrentTenant() || getTenantUser()?.tenant?.id;
  const [targetActualMetricList] = useLazyQuery(TARGET_ACTUAL_METRIC_LIST, {
    variables: {
      filter: {
        projectId,
        pageType: currentPageCount > 0 ? 'NEXT' : 'PREVIOUS',
        pageNum: Math.abs(currentPageCount),
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setTargetActualMetricsData(res?.targetActualMetricList);
      setIsLoading(false);
    },
    onError() {
      setIsLoading(false);
    },
  });
  const [updateProjectWeeklyTarget] = useMutation(
    UPDATE_PROJECT_WEEKLY_TARGET,
    {
      onCompleted() {
        targetActualMetricList();
      },
      onError() {},
    },
  );

  useEffect(() => {
    targetActualMetricList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageCount = (type) => {
    setIsLoading(true);
    if (type === 'NEXT') {
      setCurrentPageCount((prev) => prev + 3);
    } else {
      setCurrentPageCount((prev) => prev - 3);
    }
  };
  const handleInputChange = (target, id, oldValue) => {
    if (
      parseInt(oldValue, 10) !== parseInt(target, 10) &&
      (!isNaN(parseInt(oldValue, 10)) || !isNaN(parseInt(target, 10)))
    ) {
      updateProjectWeeklyTarget({
        variables: {
          id,
          target: parseInt(target, 10),
        },
      });
    }
  };

  const handleDownloadReport = (link) => {
    // eslint-disable-next-line no-undef
    window.open(link, '_blank');
  };

  const handleChange = (e, record) => {
    const {
      target: { value },
    } = e;
    if ((REGEX.NUMBER.test(value) && value?.length <= 3) || value === '') {
      setValueInput({ ...valueInput, [record?.id]: value });
    } else if (REGEX.NUMBER.test(value) && value?.length > 3) {
      message.destroy();
      message.error('Maximum 3 characters allowed!');
    } else {
      message.destroy();
      message.error('Only number are allowed to add!');
    }
  };

  const showInput = CanPerform({
    action: ALLOWED_ACTION_KEYS.ADD_TARGET_VALUE,
    type: ALLOWED_ACTION_TYPE.PROJECT,
  });

  return (
    <>
      <div className="project-tab-details">
        <div className="project-tab-details-header d-flex justify-between align-center">
          <h2 className="mr-10 d-flex align-center">
            Target Vs Achieved{' '}
            <Popover
              destroyTooltipOnHide
              getPopupContainer={() =>
                // eslint-disable-next-line no-undef
                document.querySelector('.project-tab-details')
              }
              placement={isTabletViewport ? 'right' : 'bottom'}
              overlayClassName="info-popover"
              content={
                <div onClick={(e) => e.stopPropagation()}>
                  <div>
                    <b>Target:</b> Number of EQC whose final stage is expected
                    to be passed in the week. The project admin set this.
                  </div>
                  <div>
                    <b>Achieved:</b> Number of EQC whose final stage got pass in
                    the week. This count gets auto updated as the EQC gets the
                    final pass.
                  </div>
                  <div>
                    <b>Download:</b> Cursor hovered over Achieved number will
                    get download symbol. On clicking a CSV file gets downloaded
                    with log of the inspections of that week.
                  </div>
                </div>
              }
            >
              <InfoIcon height={18} width={18} className="info-icon ml-10" />
            </Popover>
          </h2>
          {isTabletViewport && (
            <Button
              type="primary"
              shape="round"
              className="current-week-button"
              disabled={currentPageCount === 0}
              onClick={() => {
                setIsLoading(true);
                setCurrentPageCount(0);
              }}
            >
              Current Week
            </Button>
          )}
        </div>
        {!isTabletViewport && (
          <div className="d-flex justify-between mb-10">
            <Button
              icon={<ArrowLeft />}
              className="arrow-button"
              onClick={() => handlePageCount()}
              disabled={!targetActualMetricsData?.previousTargetAvailable}
            />
            <Button
              type="primary"
              shape="round"
              disabled={currentPageCount === 0}
              onClick={() => {
                setIsLoading(true);
                setCurrentPageCount(0);
              }}
            >
              Current Week
            </Button>
            <Button
              className="arrow-button"
              icon={<ArrowRight />}
              onClick={() => handlePageCount('NEXT')}
              disabled={!targetActualMetricsData?.nextTargetAvailable}
            />
          </div>
        )}
        <div className="d-flex align-center justify-between plan-vs-actual-layout">
          {isTabletViewport && (
            <Button
              icon={<ArrowLeft />}
              className="arrow-button mr-5"
              onClick={() => handlePageCount()}
              disabled={!targetActualMetricsData?.previousTargetAvailable}
            />
          )}
          <InfiniteScrollHandler
            loading={isLoading}
            dataLength={targetActualMetricsData?.data?.length || 3}
            skeletonRows={3}
          >
            <div className="d-flex d-flex-wrap width-percent-100 justify-center">
              {
                // eslint-disable-next-line no-nested-ternary
                isLoading && !targetActualMetricsData?.data?.length ? (
                  <Spin spinning />
                ) : !targetActualMetricsData?.data?.length ? (
                  <Empty />
                ) : (
                  map(targetActualMetricsData?.data, (record) => {
                    const isCurrentWeek = moment(
                      moment().startOf('week').add(1, 'day'),
                    )?.isSame(moment(record?.startDate), 'day');
                    const isEditable = moment(
                      moment().startOf('week').add(1, 'day'),
                    )?.isSameOrBefore(moment(record?.startDate), 'day');
                    const url = `${
                      process.env.REACT_APP_REPORT_SERVER_URL
                    }/api/v1/export-csv/reports?access_token=Bearer ${token}&fromDate=${moment(
                      record?.startDate,
                    )
                      .tz(currentProjectDetails?.timeZone)
                      .format(DEFAULTDATEFORMAT)}&toDate=${moment(
                      record?.endDate,
                    )
                      .tz(currentProjectDetails?.timeZone)
                      .format(
                        DEFAULTDATEFORMAT,
                      )}&projectId=${projectId}&tenantId=${tenantId}`;

                    return (
                      <CommonCard
                        className="plan-vs-actual-card mb-10"
                        loading={isLoading}
                        key={record?.id}
                      >
                        {isCurrentWeek && (
                          <Tag color="red" className="current-week-tag">
                            Current Week
                          </Tag>
                        )}
                        <div className="heading-week-div d-flex justify-center align-center">
                          <div>
                            {moment(record?.startDate)
                              .tz(currentProjectDetails?.timeZone)
                              .format(DAY_DATE_MONTH_YEAR_FORMAT)}
                          </div>
                          <div>-</div>
                          <div>
                            {moment(record?.endDate)
                              .tz(currentProjectDetails?.timeZone)
                              .format(DAY_DATE_MONTH_YEAR_FORMAT)}
                          </div>
                        </div>
                        <Card className="mt-20 statistic-card">
                          <Row
                            justify="space-between"
                            wrap={false}
                            align="middle"
                          >
                            <Col className="width-percent-50">
                              <div className="mb-10 title d-flex align-center">
                                Target
                              </div>
                              <div>
                                {showInput && isEditable ? (
                                  <Input
                                    key={record?.id}
                                    defaultValue={record?.target}
                                    className="font-size-16"
                                    disabled={!isEditable}
                                    onBlur={(e) => {
                                      handleInputChange(
                                        e?.target?.value,
                                        record?.id,
                                        record?.target,
                                      );
                                    }}
                                    placeholder="Add"
                                    value={valueInput?.[record?.id]}
                                    onChange={(e) => handleChange(e, record)}
                                  />
                                ) : (
                                  <div className="font-size-16 achieved-stats">
                                    {record?.target || '-'}
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Divider type="vertical" />
                            <Col
                              className={clsx(
                                'width-percent-50',
                                (isCurrentWeek || !isEditable) &&
                                  'achieved-col',
                              )}
                            >
                              {(isCurrentWeek || !isEditable) && (
                                <Popconfirm
                                  title="This will download report for this week. Are you sure you want to download?"
                                  onConfirm={() => handleDownloadReport(url)}
                                  okText="Yes"
                                  cancelText="No"
                                  overlayClassName="download-report-pop-confirm"
                                  disabled={record?.eqcCompletedCount <= 0}
                                  getPopupContainer={() =>
                                    // eslint-disable-next-line no-undef
                                    document.querySelector(
                                      '.plan-vs-actual-layout',
                                    )
                                  }
                                >
                                  <Button
                                    type="primary"
                                    shape="round"
                                    target="_blank"
                                    className="download-report-button"
                                    onClick={() => {
                                      if (record?.eqcCompletedCount <= 0) {
                                        message.destroy();
                                        message.error(
                                          'No inspection were done during this week',
                                        );
                                      }
                                    }}
                                    icon={<Import height={22} width={22} />}
                                  />
                                </Popconfirm>
                              )}
                              <div className="mb-10 title d-flex align-center">
                                Achieved
                              </div>
                              <div className="font-size-16 achieved-stats">
                                {record?.eqcCompletedCount || '-'}
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </CommonCard>
                    );
                  })
                )
              }
            </div>
          </InfiniteScrollHandler>
          {isTabletViewport && (
            <Button
              className="arrow-button ml-5"
              icon={<ArrowRight />}
              onClick={() => handlePageCount('NEXT')}
              disabled={!targetActualMetricsData?.nextTargetAvailable}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PlanAndTrack;
